import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ManualUploadIndex from './ManualUpload/ManualUpload.index';

export default function UploadQuestionIndex() {
    return (
        <>
            <div>
                <div className=''>
                    <ManualUploadIndex />
                </div>
            </div>
        </>
    )
}