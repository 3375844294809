import { Badge, Table, Overlay, Tooltip, OverlayTrigger } from "react-bootstrap";
import { faTimesCircle, faXmark, faCheck, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";


interface ITranslatorQuestions {
    tableData: any
}

export default function TranslatorQuestion({ tableData }: ITranslatorQuestions) {



    const navigate = useNavigate();

    const [show, setShow] = useState<boolean>(false);
    const target = useRef<any>(null)


    return (
        <Table striped hover>
            <thead>
                <tr>
                    <th>Sr.No</th>
                    <th>Subject</th>
                    <th>Topic</th>
                    <th>Language</th>
                    <th>Question Type</th>
                    <th>Option Type</th>
                    <th>Image</th>
                    <th>Audio</th>
                    <th>Video</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {tableData && tableData.finalQuestions && tableData.finalQuestions.length > 0 ? tableData.finalQuestions.map((data: any, index: number) => {
                    return (
                        <tr>
                            <td>{index + 1}</td>
                            <td>{data?.subject?.name || "--"}</td>
                            <td>{data?.topic?.name || "--"}</td>
                            <td>
                                {data?.languages && data?.languages[0]}
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip-2">
                                        {data?.languages?.map((data: any, index: number) => {
                                            return (
                                                <div>
                                                    <span>{data}  {index < data?.languages?.length - 1 && <span className="fw-bold">,</span>}</span>

                                                </div>
                                            )
                                        })}
                                    </Tooltip>}
                                >
                                    {({ ref, ...triggerHandler }) => (
                                        <span {...triggerHandler}>
                                            {
                                                data.languages && data.languages.length > 1 && <Badge bg="primary" className="ms-1" style={{ cursor: 'pointer' }} ref={ref}>+{data?.languages.length - 1}</Badge>
                                            }
                                        </span>
                                    )}
                                </OverlayTrigger>
                            </td>
                            <td>{data?.questionType || "--"}</td>
                            <td>{data?.optionType || "--"}</td>
                            <td>{data?.image ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faXmark} className="text-danger" />}</td>
                            <td>{data?.audio ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faXmark} className="text-danger" />}</td>
                            <td>{data?.video ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faXmark} className="text-danger" />}</td>
                            <td>
                                <FontAwesomeIcon icon={faEye} className="text-primary" onClick={() => navigate("/translator/" + data?.questionId)} />
                            </td>
                        </tr>
                    )
                }) : "No Question Found"}

            </tbody>
        </Table>
    )
}  