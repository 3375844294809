export class ObjectHelper {
    static removeEmpty(obj: any) {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, v]) => 
                v !== null && 
                v !== undefined && 
                v !== '' && 
                v !== 0 && 
                !(Array.isArray(v) && v.length === 0)
            )
        );
        // Example:
        // input {a: 1, b: null, c: undefined, d: 0, e: '', f: false, g: [], h: [1, 2, 3]} 
        // output {a: 1, f: false, h: [1, 2, 3]}
    }
}