import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

interface ICreateQuestionPayload {
    "selfUploaded": boolean
    "test": boolean
    "shiftId"?: string
    "examId"?: string
    "reqQuestions": {
        "number_of_questions": number
        "difficulty_level": number
        "topicId": string
        "subjectId": string
        "questionType": any
        "language": string
    }[]
}
export default class QP_GenerationService {
    // static async checkQuestionAvailability(payload:any) {
    //     return makeRequest(urls.qp_generation.checkQuestionAvailability, "post",payload);
    // }

    static async createQuestionPaper(payload: ICreateQuestionPayload) {
        return await makeRequest(urls.qp_generation.createQuestionPaper, "post",payload);
    }

    static async getCompanySubject(){
        return makeRequest(urls.qp_generation.getCompanySubject, "get")
    }

    static async getCompanyExamCategory(){
        return makeRequest(urls.qp_generation.getCompanyExamCategory, "get")
    }

    static async createQPNew(payload:any){
        return makeRequest(urls.qp_generation.createQPNew, "post", payload);
    }
}