export const urls = {
    profile: {
        getUserRoles: "/profile/getUserRoles",
        getAllQuestiontypes: "/profile/getAllQuestiontypes",
        getAllSubjects: "/profile/getAllSubjects",
    },
    public: {
        userLogin: "/public/login",
        getLanguage: "/public/getAllLanguage",
        getExamCategory: "/public/getExamCategoryEnum"
    },
    users: {
        getAllUsers: "/users/getAllUsers",
        changeUserRole: "/users/updateUserRole",
        blockUnblockUser: "/users/blockUnblockUser",
        userUpdate: "/users/userUpdate",
        register: "/users/register",
        addLanguage: "/users/addLanguage",
        getTranslatedLanguage: "/users/getUserById"
    },
    request: {
        getLogs: "/request/getLogs"
    },
    subjects: {
        insert: "/subjects/insert",
        update: "/subjects/update",
        getCompanySubjects: "/subjects/company/all",
        getSubjectById: "/subjects/getById",
        updateSubject: "/subjects/update",
        insertTopic: "/subjects/topic/insert",
        updateTopic: "/subjects/topic/update",
    },
    upload: {
        uploadMultimedia: "/upload/uploadMultimedia",
        uploadPdfQuestion: "/upload/uploadPdfQuestion",
        questionImagesZip: "/upload/questionImagesZip",
        uploadQuestion: "/upload/uploadQuestion",
        uploadComprehensionQuestion: "/upload/uploadComp",
        getCompanySubject: "/upload/getSubjectsByCompanyId",
        getCompanyExamCategory: "/upload/getexamCategoryByCompanyId",
    },
    qp_generation: {
        checkQuestionAvailability: "/qpgeneration/checkQuestionAvailability",
        createQuestionPaper: "/qpgeneration/createQuestionPaper",
        getCompanySubject: "/qpgeneration/getSubjectsByCompanyId",
        getCompanyExamCategory: "/qpgeneration/getexamCategoryByCompanyId",
        createQPNew:'/qpgeneration/qp/create',
    },
    download: {
        getAllQPForUser: "/download/getAllQPForUser",
        getQuestionPaperZip: "/download/downloadQuestionPaper",
        downloadQuestionPaper:"/download/downloadQuestionPaper",
        addUserToQuestionPaper: "/admin/client/add",
    },
    qa: {
        getAllNotVerifiedQuestionsForSubject: "/qa/getAllNotVerifiedQuestionsForSubject",
        getNotVerifiedQuestionsByCompany: "/qa/getNotVerifiedQuestionsByCompany",
        getQuestionByQuestionId: "/qa/getQuestionByQuestionId",
        getEncMultimedia: "/qa/getEncMultimedia",
        giveVerificationIssue: "/qa/giveVerificationIssue",
        updateVerificationIssue: "/qa/updateVerificationIssue",
        setVerificationOfQuestion: "/qa/setVerificationOfQuestion",
        getNextQuestions: "/qa/getNextQuestion",
        setIsEditedFalse: "/qa/setIsEditedFalse",
        setIsSolvedTrue: "/qa/setIsSolvedTrue",
        getAllTopicsBySubjectId: "/qa/topicsBySubject",
        getAllQuestions: "/qa/getQuestion",
        checkAvailability: "/qa/checkQuestionAvailability",
        updateQuestionDifficulty: "/qa/question/difficulty",
        getNextAndPrevQuestionByQuestionId: "/qa/getNextAndPreviousQuestionId",
        getCompanySubject: "/qa/getSubjectsByCompanyId",
    },
    view: {
        getUserQuestionByTypes: "/view/getUserQuestionByTypes?type=",
        getVerifiedQuestionsByUser: "/view/getVerifiedQuestionsByUser",
        getNotVerifiedQuestionsByUser: "/view/getNotVerifiedQuestionsByUser",
        getAllMarkedQuestions: "/view/getAllMarkedQuestions",
        getAllMarkedAndEditedQuestion: "/view/getAllMarkedAndEditedQuestion",
        getQuestionByQuestionId: "/view/getQuestionByQuestionId",
        uploadMultimedia: "/view/uploadMultimedia",
        updateQuestion: "/view/updateQuestion",
        updateCompQuestion: "/view/updateCompData",
        getQuestionById: "/view/getQuestionByQuestionId",
        deleteQuestion: "/view/deleteQuestion",
        getStats: "/dashboard/stats",
        getStats2: "/dashboard/stats2",
        getEncMultimedia: "/view/getEncMultimedia",
        getLanguageViewData: "/view/get/language",
        getNextAndPrevQuestionByQuestionId: "/view/getNextAndPreviousQuestionId",
        getCompanySubject: "/view/getSubjectsByCompanyId",
        getCompanyExamCategory: "/view/getexamCategoryByCompanyId",
    },
    issues: {
        setIsEditedTrue: "/issues/setIsEditedTrue"
    },
    apiKey: {
        apikeysOfCompany: "/admin/apikeysOfCompany",
        createApikey: "/users/createApikey",
        toggleApiKey: "/users/toggleApiKey"
    },

    translator: {
        getAllQuestion: "/translator/questions/get",
        getQuestionByQuestionId: "/translator/getQuestionByQuestionId",
        getUserAllowedLanguage: "/translator/getUserAllowedLanguage",
        getEncMultimedia: "/translator/getEncMultimedia",
        createAndUpdateQuestion: "/translator/questions/create",
        translateLanguageData: "/translator/translateLanguage",
        getCompanySubject: "/translator/getSubjectsByCompanyId",

    },
    examCategory: {
        getAllComapnyExamCategories: "/examCategory/company/all",
        deleteExamCategory: "/examCategory/delete",
        addExamCategory: "/examCategory/add",
        editExamCategory: "/examCategory/edit"
    },
    aiQuestion: {
        getEnums:'/ai/enums',
        create: "/ai/questions",
        save: "/ai/save/questions",
        getSavedQuestions: "/ai/saved/questions",
        approve: "/ai/approve",
        geById:'/ai/question/language',
        deleteQuestionLanguage: "/ai/question/language/delete",
        saveAIQuestionLanguage: "/ai/question/language/edit",
    },
    aiQa:{
        addRemarks:"/ai-qa/verification",
        resolveAllRemarks:"/ai-qa/mark-resolved"
    }


}