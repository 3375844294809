import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Button, Card, Form, Spinner } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AIQAservice } from '../../services/AIQa.service';

interface IRemarks {
    questionData: any,
    reload: any
}


const AiQARemarks = ({ questionData, reload }: IRemarks) => {

    const { questionId } = useParams();
    const [textData, setTextData] = useState<any>();

    const handleChangeValue = (e: any) => {
        setTextData(e.target.value);
    }

    const handleVerificationIssue = async () => {
        if (!textData) {
            toast.error("Please fill your remarks..");
            return;
        } else {
            await AIQAservice.addRemarks(questionId, { marking: false, remarks: textData })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success("Remarks saved successfully");
                        setTextData("");
                        reload();
                    }
                }).catch((err) => {
                    toast.error("Unable to save your remarks.")
                })
        }
    }

    const resolveRemark = async (remarkId: string) => {
        await AIQAservice.resolveAllRemarks(questionId, remarkId)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Remark accepted");
                    reload();
                }
            })
            .catch((err) => {
                toast.error("Unable to accept your remarks.")
            })
    }

    async function verifyQuestion() {
        await AIQAservice.addRemarks(questionId, { marking: true })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Question verified successfully");
                    reload();
                }
            })
            .catch((err) => {
                toast.error("Unable to verify your question.")
            })
    }


    return (
        <>
            <Card className="reg-card box-shadow mt-3 mb-4 ms-3">
                <Card.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <Form.Label className='fw-bold xcn-text-15'>Remarks</Form.Label>
                        </div>
                        <Form.Control as="textarea" rows={10} name="remarks" onChange={handleChangeValue} required />
                    </Form.Group>
                    <div className="mt-4">
                        <Button variant="danger" className="fw-bold text-white" size="sm" onClick={handleVerificationIssue}>Add Remark</Button>
                    </div>
                    <hr />
                    <Button className="me-3 fw-bold text-white mt-1 mb-4" variant="success" size="sm" onClick={verifyQuestion}>Mark All Remarks as Solved and Verify Question</Button>
                    <div>
                        <h3>Past Remarks</h3>
                        {questionData && questionData.verification_issues.length > 0 ? questionData.verification_issues.map((remark: any) => {
                            return (
                                <Card className="reg-card box-shadow border mt-3 mb-4">
                                    <Card.Body>
                                        <>
                                            <p className="text-muted text-italic" style={{ fontSize: "15px", fontWeight: "500" }}>{remark.remarks}</p>
                                            <div className='d-flex align-items-center'>
                                                <span className='fw-bold xcn-text-25'>Solved: </span>
                                                <span className='fw-bold xcn-text-25 ms-2'> {remark.isSolved === true ? "Yes" : "No"}</span>
                                            </div>
                                            {remark.isSolved === false &&
                                                <>
                                                    <Button variant="success" className="text-white" size="sm" onClick={() => resolveRemark(remark?._id)}>
                                                        <FontAwesomeIcon icon={faCheck} className="me-2" />Mark As resolved
                                                    </Button>

                                                    {/* <FontAwesomeIcon icon={faXmark} className="cursor-pointer text-danger" onClick={() => handleUnSolvedRemarks(remark._id)} /> */}
                                                </>
                                            }
                                        </>
                                    </Card.Body>
                                </Card>
                            )
                        }) : "No Remarks Found."}
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default AiQARemarks;