import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import { MdOutlineNavigateNext } from 'react-icons/md'
import Select from 'react-select'
import { TranslatorService } from '../../services/translator.service';
import UserProfileService from '../../services/userprofile.service';
import { makeParams } from '../../api/make.request';
import { AiQuestionService } from '../../services/aiQuestion.service';
import AiQAQuestionsTable from '../../components/Table/AiQAQuestionsTable';
import TablePagination from '../../components/Pagination/Table.paginaition';

export default function AiQAIndex() {
    const [subjects, setSubjects] = useState<any>([]);
    const [topics, setTopics] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [qaData, setQaData] = useState<any>({
        subject: null,
        topic: null,
        status: null
    });
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [data, setData] = useState<any[]>([]);


    const handleSelectValue = (e: any, name: string) => {
        setQaData({ ...qaData, [name]: e })
    }

    const getCompanySubject = async () => {
        await TranslatorService.getCompanySubject().then((res) => {
            if (res.status === 200) {
                const subjects = res.data.map((data: any) => {
                    return {
                        label: data.name,
                        value: data._id,
                    };
                });
                setSubjects(subjects);
            }
        })
    }

    const getTopicsAndSubTopics = async (selectedSubjectId?: any) => {
        try {
            const res = await UserProfileService.getAllSubjects();
            if (res.status === 200) {
                const selectedSubject = res.data.find((data: any) => data._id === selectedSubjectId);

                if (selectedSubject) {
                    const topics = selectedSubject.topics.map((topic: any) => ({
                        label: topic.name,
                        value: topic._id,
                    }));
                    setTopics(topics);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    async function getAllQuestions() {
        const params = makeParams([
            { index: "pageNumber", value: pageNumber },
            { index: "pageSize", value: pageSize },
            { index: "subject", value: qaData?.subject?.value },
            { index: "topic", value: qaData?.topic?.value },
            { index: "status", value: qaData?.status?.value },
        ]);

        setLoading(true)
        await AiQuestionService.getAiSavedQuestions(params)
            .then((res) => {
                if (res.status === 200) {
                    setData(res.data?.finalData || []);
                    setTotalCount(res.data?.totalCount || 0);
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getCompanySubject();
    }, []);

    useEffect(() => {
        if (qaData?.subject?.value) getTopicsAndSubTopics(qaData?.subject?.value);
    }, [qaData?.subject?.value]);

    useEffect(() => {
        getAllQuestions();
    }, [pageNumber, pageSize]);

    return (
        <>
            <Card className="reg-card box-shadow mt-2">
                <Card.Header>
                    <Card.Title>AI Questions (QA)</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Label className="fw-bold xcn-text-12 text-muted"> 1. Select Subject</Form.Label>
                            <Select
                                className="select"
                                options={subjects}
                                value={qaData?.subject}
                                onChange={(e: any) => handleSelectValue(e, "subject")}
                                isSearchable
                                isClearable
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Label className="fw-bold xcn-text-12 text-muted">2. Select Topics</Form.Label>
                            <Select
                                options={topics}
                                onChange={(e: any) => handleSelectValue(e, "topic")}
                                value={qaData?.topic}
                                isSearchable
                                isClearable
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Label className="fw-bold xcn-text-12 text-muted">3. Status</Form.Label>
                            <Select
                                options={status}
                                value={qaData?.status}
                                onChange={(e: any) => handleSelectValue(e, "status")}
                                isSearchable

                            />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                        <Button size="sm" className="mt-3 xcn-text-12 fw-bold" onClick={getAllQuestions} disabled={loading} >
                            {
                                !loading ?
                                    <>
                                        Next <MdOutlineNavigateNext className="text-white" />
                                    </>
                                    :
                                    <span className="d-flex align-items-center justify-content-center gap-2">
                                        Loading <Spinner animation="border" variant="light" size="sm" />
                                    </span>
                            }
                        </Button>
                    </div>
                </Card.Body>
            </Card>

            {
                <Card className="reg-card box-shadow mt-2">
                    <Card.Body>
                        {
                            loading ?
                                <span className="d-flex align-items-center justify-content-center gap-2">
                                    Loading <Spinner animation="border" variant="light" size="sm" />
                                </span>
                                :
                                <>
                                    <AiQAQuestionsTable
                                        data={data}
                                        loading={loading}
                                        reload={getAllQuestions}
                                    />
                                    <TablePagination
                                        total={totalCount}
                                        currentPage={pageNumber}
                                        perPage={pageSize}
                                        handlePageChange={(e: number) => {
                                            setPageNumber(e);
                                        }}
                                        setPerPage={(e: number) => { setPageSize(e); }}
                                    />
                                </>
                        }
                    </Card.Body>
                </Card>
            }
        </>
    )
}

const status = [
    {
        value: "Verified",
        label: "Verified"
    },
    {
        value: "Not Verified",
        label: "Not-Verified"
    }
];