import { urls } from "../api/api.urls";
import makeRequest, { makeParams } from "../api/make.request";
import makeUploadRequest from "../api/make.upload";

export default class ViewService {

    static async getUserQuestionByTypes(type: string) {
        return makeRequest(urls.view.getUserQuestionByTypes + type, "GET");
    }

    static async getVerifiedQuestionsByUser(pageNumber: number, pageSize: number) {
        const params = makeParams([
            {
                index: "pageNumber",
                value: pageNumber
            },
            {
                index: "pageSize",
                value: pageSize
            },
        ])
        return makeRequest(urls.view.getVerifiedQuestionsByUser + params, "GET");
    }

    static async getNotVerifiedQuestionsByUser(pageNumber: number, pageSize: number) {
        const params = makeParams([
            {
                index: "pageNumber",
                value: pageNumber
            },
            {
                index: "pageSize",
                value: pageSize
            },
        ])
        return makeRequest(urls.view.getNotVerifiedQuestionsByUser + params, "GET");
    }

    static async getAllMarkedQuestions(pageNumber: number, pageSize: number) {
        const params = makeParams([
            {
                index: "pageNumber",
                value: pageNumber
            },
            {
                index: "pageSize",
                value: pageSize
            },
        ])
        return makeRequest(urls.view.getAllMarkedQuestions + params, "GET");
    }

    static async getAllMarkedAndEditedQuestion(pageNumber: number, pageSize: number) {
        const params = makeParams([
            {
                index: "pageNumber",
                value: pageNumber
            },
            {
                index: "pageSize",
                value: pageSize
            },
        ])
        return makeRequest(urls.view.getAllMarkedAndEditedQuestion + params, "GET");
    }

    static async getQuestionByQuestionId(questionId: any) {
        return makeRequest(urls.view.getQuestionByQuestionId + '/' + questionId, "GET");
    }

    static async uploadMultimedia(formData: any) {
        return makeUploadRequest(urls.view.uploadMultimedia, "post", formData);
    }
    static async sendForReverification(verificationId: string) {
        return makeRequest(urls.issues.setIsEditedTrue + "/" + verificationId, "put");
    }

    static async updateQuestion(questionId: string, questionData: any) {
        return makeRequest(urls.view.updateQuestion + "/" + questionId, "PUT", questionData);
    }

    static async updateCompQuestion(questionId: string, questionData: any) {
        return makeRequest(urls.view.updateCompQuestion + "/" + questionId, "PUT", questionData);
    }

    static async getQuestionbyQuestionId(questionId: any) {
        return await makeRequest(urls.view.getQuestionById + "/" + questionId, "GET");
    }

    static async deleteQuestion(subjectId: any) {
        return await makeRequest(urls.view.deleteQuestion + "/" + subjectId, "DELETE");
    }
    static async getStats() {
        return await makeRequest(urls.view.getStats, "GET");
    }


    static async getEncMultimedia(url: any) {
        return await makeRequest(urls.view.getEncMultimedia + "?url=" + url, "GET");
    }

    static async getLanguageViewData(questionId?: any, tab?: any) {
        const params = makeParams([
            {
                index: "tab",
                value: tab
            },
            {
                index: "questionId",
                value: questionId
            },
        ])
        return await makeRequest(urls.view.getLanguageViewData + "/" + questionId + "/" + tab, "GET")
    }

    static async getNextAndPrevQuestionByQuestionId(questionId: any, moveValue: string, status: any) {
        const params = makeParams([
            {
                index: "value",
                value: moveValue
            },
            {
                index: "status",
                value: status
            },
        ])
        return await makeRequest(urls.view.getNextAndPrevQuestionByQuestionId + "/" + questionId + params, "GET")
    }

    static async getCompanySubject(){
        return makeRequest(urls.view.getCompanySubject, "get")
    }

    static async getCompanyExamCategory(){
        return makeRequest(urls.view.getCompanyExamCategory, "get")
    }

    static async getStats2(){
        return makeRequest(urls.view.getStats2, "get")
    }

}