import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

export class AiQuestionService {

    static async createAIQuestion(payload: any) {
        return makeRequest(urls.aiQuestion.create, "post", payload);
    }

    static async saveAiQuestion(params: any, payload: any) {
        return makeRequest(urls.aiQuestion.save + params, "post", payload);
    }

    static async getAiSavedQuestions(params: any) {
        return makeRequest(urls.aiQuestion.getSavedQuestions + params, "get")
    }

    static async getAiEnums() {
        return makeRequest(urls.aiQuestion.getEnums, "get");
    }

    static async approveQuestion() {
        return makeRequest(urls.aiQuestion.approve, "post");
    }

    static async getQuestionByQuestionId(questionId: any) {
        return makeRequest(urls.aiQuestion.geById + "/" + questionId, "get");
    }

    static async deleteQuestionLanguage(languageId: string) {
        return makeRequest(urls.aiQuestion.deleteQuestionLanguage + "/" + languageId, "delete");
    }

    static async updateAIQuestionLanguage(languageId: any, payload: any) {
        return makeRequest(urls.aiQuestion.saveAIQuestionLanguage + "/" + languageId, "put", payload);
    }
}