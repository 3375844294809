import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import Select from 'react-select';
import toast from 'react-hot-toast';
// import '../Topics/Topics.css'
import TopicTabs from './TopicTabs'
import UserProfileService from '../../services/userprofile.service';
import TextEditor from '../../components/TextEditor/TextEditor';
import QP_GenerationService from '../../services/qp_generation.service';

const QPGenerationIndex = () => {

    const [subjectsList, setSubjectsList] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    const [selectedSubjects, setSelectedSubjects] = useState([])
    // let [options, setOptions] = useState([]);
    let [opt, setOpt] = useState([]);

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const res = await QP_GenerationService.getCompanySubject();
                if (res.status === 200) {
                    setSubjectsList(res.data);
                    const formattedOptions = res.data.map((data: any) => ({
                        ...data,
                        value: data._id,
                        label: data.name,
                    }));
                    setOpt(formattedOptions);
                }
            } catch (error) {
                console.error("Error fetching subjects:", error);
                toast.error("Something went wrong while fetching subjects.");
            }
        };

        fetchSubjects();
    }, []);




    return (
        <>
            <div className="p-3 reg-card box-shadow card">
                <h4>Question Paper Generation</h4>
                <hr />
                <h5>Select Subjects</h5>
                <div className='col-md-6'>
                    <Select
                        defaultValue={[]}
                        isMulti
                        name="subjects"
                        options={opt}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e: any) => setSelectedSubjects(e)}
                        isSearchable
                    />
                </div>
                <TopicTabs subjects={selectedSubjects} />
            </div>
            {/* <TextEditor /> */}
        </>
    )
}

export default QPGenerationIndex