import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Button, Card, Form, Spinner } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import QAService from '../../services/qa.service';

interface IRemarks {
    questionData: any,
    reload: any
}


const Remarks = ({ questionData, reload }: IRemarks) => {


    const navigate = useNavigate();
    const tab = localStorage.getItem("qaValue")

    const { questionId } = useParams();

    const [textData, setTextData] = useState<any>();
    const [nextLoading, setNextLoading] = useState<boolean>(false)
    const [prevLoading, setPrevLoading] = useState<boolean>(false)

    const handleChangeValue = (e: any) => {
        setTextData({ ...textData, [e.target.name]: e.target.value })
    }


    const handleNextQuestion = async () => {
        setNextLoading(true)
        await QAService.getNextAndPrevQuestionByQuestionId(questionId, "next", tab, questionData?.questionType).then((res: any) => {
            if (res.status === 200) {
                navigate(`/qa/${res.data.data.previousQuestionId}`);
            }
        }).catch(err => {
            console.log(err)
        })
        setNextLoading(false)
    }

    const handlePrevQuestion = async () => {
        setPrevLoading(true)
        await QAService.getNextAndPrevQuestionByQuestionId(questionId, "previous", tab, questionData?.questionType).then((res: any) => {
            if (res.status === 200) {
                navigate(`/qa/${res.data.data.previousQuestionId}`);
            }
        }).catch(err => {
            console.log(err)
        })
        setPrevLoading(false)
    }


    const handleVerificationIssue = async () => {
        if (!textData) {
            toast.error("Please fill your remarks..")
        } else {
            await QAService.giveVerificationIssue(textData, questionId).then((res) => {
                if (res.status === 200) {
                    toast.success("Remarks saved successfully");
                    reload();
                }
            }).catch((err) => {
                toast.error("Unable to save your remarks.")
            })
        }
    }

    const handleRemarks = async () => {
        await QAService.setVerificationIssue({ "isVerified": true }, questionId).then((res) => {
            if (res.status === 200) {
                toast.success("Remark accepted");
            }
        })
    }

    const handleUnSolvedRemarks = async (remarkId: any) => {
        await QAService.setIsEditedFalse(remarkId, { "isSolved": true }).then((res) => {
            if (res.status === 200) {
                toast.success("Remark solved.");
                reload();
            }
        }).catch(err => {
            toast.error("Unable to solved your remarks.")
        })
    }

    const handleSolvedRemarks = async (remarkId: any) => {
        await QAService.setIsSolvedTrue(remarkId).then((res) => {
            if (res.status === 200) {
                toast.success("Remark solved.");
                reload();
            }
        }).catch(err => {
            toast.error("Unable to solved your remarks.")
        })
    }


    return (
        <>
            <Card className="reg-card box-shadow mt-3 mb-4 ms-3" style={{ width: "35rem" }}>
                <Card.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <Form.Label className='fw-bold xcn-text-15'>Remarks</Form.Label>
                            <div className="d-flex gap-2">
                                {prevLoading ?
                                    <Button className="fw-bold" disabled={true}> <Spinner animation="border" size="sm" className="me-2" />Prev</Button> :
                                    <Button className="fw-bold" onClick={handlePrevQuestion}>{"<<"}Prev</Button>
                                }
                                {nextLoading ?
                                    <Button className="fw-bold" disabled={true}> <Spinner animation="border" size="sm" className="me-2" />Next</Button> :
                                    <Button className="fw-bold" onClick={handleNextQuestion}>Next {">>"}</Button>
                                }
                            </div>
                        </div>
                        <Form.Control as="textarea" rows={10} name="remarks" onChange={handleChangeValue} required />
                    </Form.Group>
                    <div className="mt-4">
                        <Button variant="danger" className="fw-bold text-white" size="sm" onClick={handleVerificationIssue}>Add Remark</Button>
                    </div>
                    <hr />
                    <Button className="me-3 fw-bold text-white mt-1 mb-4" variant="success" size="sm" onClick={handleRemarks}>Mark All Remarks as Solved and Verify Question</Button>

                    <div>
                        <h3>Past Remarks</h3>
                        {questionData && questionData.verification_issues.length > 0 ? questionData.verification_issues.map((remark: any) => {
                            return (
                                <Card className="reg-card box-shadow mt-3 mb-4">
                                    <Card.Body>
                                        <>
                                            <p className="text-muted text-italic" style={{ fontSize: "15px", fontWeight: "500" }}>{remark.remarks}</p>
                                            <div className='d-flex align-items-center'>
                                                <span className='fw-bold xcn-text-25'>Solved: </span>
                                                <span className='fw-bold xcn-text-25 ms-2'> {remark.isSolved === true ? "Yes" : "No"}</span>

                                                {remark.isSolved === false && remark.isEdited === true ?
                                                    <div>
                                                        <FontAwesomeIcon icon={faCheck} className="text-success me-3 ms-2" onClick={() => handleSolvedRemarks(remark._id)} />
                                                        <FontAwesomeIcon icon={faXmark} className="text-danger" onClick={() => handleUnSolvedRemarks(remark._id)} />
                                                    </div>
                                                    : ""}
                                            </div>
                                        </>
                                    </Card.Body>
                                </Card>
                            )
                        }) : "No Remarks Found."}
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default Remarks