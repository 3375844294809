import { Table, Spinner, Badge, Button, Card, Dropdown } from "react-bootstrap";
import { faCheck, faEllipsisVertical, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import BasicModal from "../Modals/Basic.modal";
import { MCQQuestionCard } from "../../views/AiQuestions/Questions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CustomToggle from "../../Menu/CustomMenu";


interface ITranslatorQuestions {
    loading: boolean;
    data: any;
    reload: any;
}

export default function AiQAQuestionsTable({ data, loading, reload }: ITranslatorQuestions) {

    const [selectedQuestion, setSelectedQuestion] = useState<any>(null);

    return (
        <>
            {data && data.length > 0 && data.map((data: any, index: number) => {
                return (

                    <Card className="mt-3 ">
                        <Card.Body>
                            <div className="d-flex align-items-center">
                                <p className="fw-bold">No:</p>
                                <p className="ms-2">{index + 1}</p>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <div className="d-flex align-items-center">
                                    <p className="fw-bold">Difficulty:</p>
                                    <p className="ms-2">{data?.questionsData?.difficulty}</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fw-bold">Subject:</p>
                                    <p className="ms-2">{data?.questionsData?.subjectId?.name}</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fw-bold">Topic:</p>
                                    <p className="ms-2">{data?.questionsData?.topicId?.name}</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fw-bold">Internal Id:</p>
                                    <p className="ms-2">{data?.questionsData?._id}</p>
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <p className="fw-bold">Question:</p>
                                <p className="ms-2">{data?.questionLanguage?.[0]?.question}</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="fw-bold">Created By:</p>
                                <p className="ms-2">{data?.questionsData?.createdBy?.username || data?.questionsData?.createdBy?.name}</p>
                            </div>

                            <p className="fw-bold mb-0">Languages:</p>
                            <QuestionLanguageTable
                                data={data.questionLanguage}
                                loading={loading}
                            />
                        </Card.Body >
                    </Card >
                )
            })
            }

            <BasicModal
                show={selectedQuestion?._id}
                setShow={() => setSelectedQuestion(null)}
                size="lg"
                heading="Question Details"
                body={
                    <>
                        <MCQQuestionCard data={selectedQuestion?.metaData} showSelect={false} />
                        <div className="d-flex justify-content-end gap-2">
                            <Button size="sm" variant="secondary" onClick={() => setSelectedQuestion(null)}>
                                Close
                            </Button>
                            <Button size="sm" variant="primary">
                                Approve Question
                            </Button>
                        </div>
                    </>
                }
            />
        </>
    )
}

function QuestionLanguageTable({ data, loading }: { data: any[], loading: boolean }) {
    const navigate = useNavigate();
    return (
        <>
            <Table hover className="border rounded-2 p-2 mt-3">
                <thead>
                    <tr className='bg-light'>
                        <th>Sr No.</th>
                        <th>Language</th>
                        <th>Image</th>
                        <th>Audio</th>
                        <th>Video</th>
                        <th>Verified</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((data: any, index: number) => {
                        return (
                            <tr>
                                <td className=''>
                                    {index + 1}
                                </td>
                                <td className="xcn-text-10">
                                    {data.language?.toUpperCase()}
                                </td>
                                <td className="xcn-text-10">
                                    {data.image ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                                </td>
                                <td className="xcn-text-10">
                                    {data.audio ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                                </td>
                                <td className="xcn-text-10">
                                    {data.video ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                                </td>
                                <td className="xcn-text-10">
                                    {data.isVerified ? <span className="text-success">Yes</span> : "No"}
                                </td>
                                <td className="xcn-text-10">
                                    {moment(data.createdAt).format("DD/MM/YYYY hh:mm a")}
                                </td>
                                <td className="xcn-text-10">
                                    {moment(data.updatedAt).format("DD/MM/YYYY hh:mm a")}
                                </td>
                                <td>
                                    <Dropdown style={{ cursor: "pointer" }}>
                                        <Dropdown.Toggle
                                            as={CustomToggle}
                                            id="dropdown-custom-components"
                                        >
                                            <FontAwesomeIcon icon={faEllipsisVertical} className="text-primary" size="sm" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>

                                            <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold" onClick={() => navigate(`/ai_qa/${data?._id}`)}>
                                                <span className='text-primary xcn-text-10'> <FontAwesomeIcon icon={faEye} className="ms-2" />  View </span>
                                            </Dropdown.Item>

                                            {/* <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold" onClick={() => navigate(`/view/edit/${questionId}/language/` + data._id)}>
                                            <span className="text-warning xcn-text-10"><FontAwesomeIcon icon={faPencil} className="ms-2" /> Edit</span>
                                        </Dropdown.Item>


                                        <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                            onClick={() => {
                                                deleteQuestion(data._id)
                                            }}
                                        >
                                            <span className="text-danger xcn-text-10"  ><FontAwesomeIcon icon={faTrash} className="ms-2" /> Delete</span>
                                        </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    )
}