import { faCheck, faDotCircle, faEllipsisVertical, faEye, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Dropdown, Table } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import toast from 'react-hot-toast';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { json } from 'stream/consumers';
import CustomToggle from '../../Menu/CustomMenu';
import ViewService from '../../services/view.service';
import { BsArrowLeft } from 'react-icons/bs';
import QuestionCard from './QuestionCard';
import TablePagination from '../../components/Pagination/Table.paginaition';
import Select from "react-select"

export default function ViewIndex() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [questions, setQuestions] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [activeTab, setActiveTab] = useState<string>(searchParams.get('tab') || 'marked');

    const [selectedData, setSelectedData] = useState<any>()
    const [notVerifiedData, setNotVerifiedData] = useState<any>()

    const verified_question_type = [
        {
            label: "Verified Question",
            value: "verified"
        },
        {
            label: "Verified Comprehension Question",
            value: "verifiedComprehension"
        },
    ]

    const not_verified_question_type = [
        {
            label: "Not Verified Question",
            value: "notVerified"
        },
        {
            label: "Not Verified Comprehension Question",
            value: "notVerifiedComprehension"
        },
    ]

    const getVerifiedQuestionsByUser = async () => {
        setLoading(true);
        await ViewService.getVerifiedQuestionsByUser(currentPage, perPage).then((res: any) => {
            const toastId = toast.loading("Verified Questions : Loading....");
            if (selectedData?.value === "verified") {
                setQuestions(res.data.finalData);
                setTotalCount(res.data.totalCount);
            } else {
                setQuestions(res.data.compFinalData);
                setTotalCount(res.data.compTotalCount);
            }
            toast.remove(toastId);
        })
            .catch(err => {
                console.error(err);
                setQuestions([]);
                toast.error(err.response.data);
            })
            .finally(() => setLoading(false));
    }

    const getNotVerifiedQuestionsByUser = async () => {
        setLoading(true);
        await ViewService.getNotVerifiedQuestionsByUser(currentPage, perPage).then((res: any) => {
            const toastId = toast.loading("Not Verified Questions : Loading....");
            if (notVerifiedData?.value === "notVerified") {
                setQuestions(res.data.finalData);
                setTotalCount(res.data.totalCount);
            } else {
                setQuestions(res.data.compFinalData);
                setTotalCount(res.data.compTotalCount);
            }
            toast.remove(toastId);
        })
            .catch(err => {
                console.error(err);
                setQuestions([]);
                toast.error(err.response.data);
            })
            .finally(() => setLoading(false));
    }

    const getAllMarkedQuestions = async () => {
        setLoading(true);
        await ViewService.getAllMarkedQuestions(currentPage, perPage).then((res: any) => {
            const toastId = toast.loading("Marked Questions : Loading....");
            setQuestions(res.data.finalData);
            setTotalCount(res.data.totalCount);
            toast.remove(toastId);
        })
            .catch(err => {
                console.error(err);
                setQuestions([]);
                toast.error(err.response.data);
            })
            .finally(() => setLoading(false));
    }

    const getAllMarkedAndEditedQuestion = async () => {
        setLoading(true);
        await ViewService.getAllMarkedAndEditedQuestion(currentPage, perPage).then((res: any) => {
            const toastId = toast.loading("Marked and Edited Questions : Loading....");
            setQuestions(res.data.finalData);
            setTotalCount(res.data.totalCount);
            toast.remove(toastId);
        })
            .catch(err => {
                console.error(err);
                setQuestions([]);
                toast.error(err.response.data);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        setCurrentPage(1);
        setPerPage(10);
        if (activeTab === 'marked_and_edited') getAllMarkedAndEditedQuestion();
        else if (activeTab === 'verified') getVerifiedQuestionsByUser();
        else if (activeTab === 'unverified') getNotVerifiedQuestionsByUser();
        else getAllMarkedQuestions();
    }, [activeTab]);

    useEffect(() => {
        if (activeTab === 'marked_and_edited') getAllMarkedAndEditedQuestion();
        else if (activeTab === 'verified') getVerifiedQuestionsByUser();
        else if (activeTab === 'unverified') getNotVerifiedQuestionsByUser();
        else getAllMarkedQuestions();
    }, [currentPage, perPage, selectedData, notVerifiedData]);

    const handleTabChange = (e: any) => {
        setSearchParams({ tab: e });
        setActiveTab(e);
        localStorage.setItem("tab", e)
    }

    console.log(selectedData, notVerifiedData)

    useEffect(() => {
        setSelectedData(verified_question_type[0])
        setNotVerifiedData(not_verified_question_type[0])
    }, [activeTab])

    return (
        <>
            <Card className="reg-card box-shadow p-3 mt-3">
                <div className='my-3'>
                    <h4 className='mb-3'>My Questions</h4>
                    <Tabs
                        activeKey={activeTab}
                        transition={false}
                        id="justify-tab-example"
                        className="mb-3 text-center"
                        justify
                        onSelect={handleTabChange}
                    >
                        <Tab eventKey="marked" title="Marked for Review" tabClassName='fw-normal'>
                            <QuestionCard data={questions} loading={loading} />
                        </Tab>
                        <Tab eventKey="verified" title="Verified" tabClassName='fw-normal'>
                            <div className="d-flex justify-content-end">
                                <Select
                                    options={verified_question_type}
                                    value={selectedData}
                                    onChange={(e: any) => setSelectedData(e)}
                                />
                            </div>
                            <QuestionCard data={questions} loading={loading} />
                        </Tab>
                        <Tab eventKey="unverified" title="Not Verified" tabClassName='fw-normal'>
                            <div className="d-flex justify-content-end">
                                <Select
                                    options={not_verified_question_type}
                                    value={notVerifiedData}
                                    onChange={(e: any) => setNotVerifiedData(e)}
                                />
                            </div>
                            <QuestionCard data={questions} reload={getNotVerifiedQuestionsByUser} loading={loading} />
                        </Tab>
                        <Tab eventKey="marked_and_edited" title="Sent for Re-verification" tabClassName='ffw-normal'>
                            <QuestionCard data={questions} loading={loading} />
                        </Tab>
                    </Tabs>
                </div>
            </Card>
            <div className="text-center my-3">
                <TablePagination
                    total={totalCount}
                    currentPage={currentPage}
                    perPage={perPage}
                    handlePageChange={(e: number) => {
                        setCurrentPage(e);
                    }}
                    setPerPage={(e: number) => { setPerPage(e); }}
                />
            </div>
        </>
    );
}


const TableComponent = ({ tableData, reload }: any) => {
    const deleteQuestion = async (Id: any) => {
        await ViewService.deleteQuestion(Id).then((res) => {
            if (res.status === 200) {
                toast.success("Question Deleted Successfully");
                reload();
            }
        }).catch(err => {
            toast.error("Unable to delete")
        })
    }
    const navigate = useNavigate();
    return (
        <>

            <Table hover responsive>
                <thead>
                    <tr className='bg-light'>
                        <th></th>
                        <th>Image</th>
                        <th>Audio</th>
                        <th>Video</th>
                        <th>Subject</th>
                        <th>Difficulty</th>
                        <th>Verified</th>
                        <th>Language</th>
                        <th>Option Type</th>
                        <th>Question Type</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tableData?.map((data: any, index: number) => {
                        return (
                            <tr>
                                <td className=''>
                                    {index + 1}
                                </td>
                                <td className="xcn-text-10">
                                    {data.image ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                                </td>
                                <td className="xcn-text-10">
                                    {data.audio ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                                </td>
                                <td className="xcn-text-10">
                                    {data.video ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                                </td>
                                <td className="xcn-text-10">
                                    {data.subjectId && data.subjectId.name || "---"}
                                </td>
                                <td className="font-monospace xcn-text-12">
                                    {data.difficulty}
                                </td>
                                <td className="xcn-text-10">
                                    {data.isVerified ? <span className="text-success">Yes</span> : "No"}
                                </td>
                                <td className="xcn-text-10">
                                    {data.language?.toUpperCase()}
                                </td>
                                <td className="xcn-text-10">
                                    {data.optionType?.toUpperCase()}
                                </td>
                                <td className="xcn-text-10">
                                    {data.questionType?.toUpperCase()}
                                </td>
                                <td className="xcn-text-10">
                                    {moment(data.createdAt).format("DD/MM/YYYY hh:mm a")}
                                </td>
                                <td className="xcn-text-10">
                                    {moment(data.updatedAt).format("DD/MM/YYYY hh:mm a")}
                                </td>
                                <td>
                                    <Dropdown style={{ cursor: "pointer" }}>
                                        <Dropdown.Toggle
                                            as={CustomToggle}
                                            id="dropdown-custom-components"
                                        >
                                            <FontAwesomeIcon icon={faEllipsisVertical} className="text-primary" size="sm" />

                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>

                                            <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold" onClick={() => window.location.href = (`/view/` + data._id)}>
                                                <span className='text-primary xcn-text-10'> <FontAwesomeIcon icon={faEye} className="ms-2" />  View </span>
                                            </Dropdown.Item>

                                            <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold" onClick={() => window.location.href = (`/view/edit/` + data._id)}>
                                                <span className="text-warning xcn-text-10"><FontAwesomeIcon icon={faPencil} className="ms-2" /> Edit</span>
                                            </Dropdown.Item>


                                            <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                                onClick={() => {
                                                    deleteQuestion(data._id)
                                                }}
                                            >
                                                <span className="text-danger xcn-text-10"  ><FontAwesomeIcon icon={faTrash} className="ms-2" /> Delete</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    )
}

