import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

export class AIQAservice{
    static async addRemarks(questionId: any, payload: any) {
        return await makeRequest(urls.aiQa.addRemarks + '/' + questionId, "POST", payload);
    }

    static async resolveAllRemarks(languageId: any, questionId: any) {
        return await makeRequest(urls.aiQa.resolveAllRemarks + '/' + languageId + '/' + questionId, "PUT");
    }
}