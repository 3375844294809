import { faDownload, faEllipsisVertical, faFileExcel, faFilePdf, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, Form, Row, Spinner, Table } from 'react-bootstrap';
import DownloadQpService from '../../services/downloadQp.service';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import CustomToggle from '../../Menu/CustomMenu';
import toast from 'react-hot-toast';
import BasicModal from '../../components/Modals/Basic.modal';

const download = require("downloadjs");

export default function QPDownloadIndex() {

    const [questionPaper, setQuestionPaper] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedQp, setSelectedQp] = useState<string>('');
    const [userDetails, setUserDetails] = useState<{ email: string, name: string }>({
        email: '',
        name: ''
    });

    const getAllCreatedQuestionPapers = async () => {
        setLoading(true);
        await DownloadQpService.getAllQPForUser().then(res => {
            if (res.status === 200) {
                setQuestionPaper(res.data)
            }
        })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const downloadQuestionPaperZip = async (qp_id: any) => {
        await DownloadQpService.getQuestionPaperZip(qp_id).then(res => {
            if (res.status === 200) {
                download(res.data, "qp.zip", "application/zip");
            }
        })
    }


    const handleQuestionPaperDownload = async (questionId: any) => {
        toast.promise(DownloadQpService.downloadQuestionPaper(questionId).then((res) => {
            if (res.status === 200) {
                const a = document.createElement('a');
                a.href = res.data;
                a.download = `QuestionPaper_${questionId}.zip`;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
            }
        }), {
            loading: 'Downloading...',
            success: 'File downloaded',
            error: 'Unable to download this file.',
        });
    }

    function handleAddUser() {
        if (!selectedQp) return;
        if (!userDetails.email || !userDetails.name) {
            toast.error('Please enter email and name');
            return;
        }
        let payload = {
            name: userDetails.name,
            email: userDetails.email,
            qpId: selectedQp
        }

        toast.promise(
            DownloadQpService.addUserToQuestionPaper(payload),
            {
                loading: 'Uploading...',
                success: (res) => {
                    if (res.status === 200) {
                        getAllCreatedQuestionPapers();
                        setSelectedQp('');
                        setUserDetails({ email: '', name: '' });
                    }
                    return "Question Updated Successfully";
                },
                error: "Something went wrong while adding user.",
            }
        )
    }



    useEffect(() => {
        getAllCreatedQuestionPapers()
    }, [])

    if (loading) {
        return (
            <Skeleton height={600} />
        )
    }

    return (
        <>
            <div className="mt-2">
                <Card className="reg-card bg-white">
                    <Card.Body>

                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>
                                        S.No.
                                    </th>
                                    <th>
                                        ExamId
                                    </th>
                                    <th>
                                        ShiftId
                                    </th>
                                    <th>
                                        Question Paper Id
                                    </th>
                                    <th>
                                        CreatedAt
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ? <tr><td colSpan={5} className="text-center">Loading... <Spinner animation="border" variant="primary" /> </td></tr> :
                                        questionPaper && questionPaper.length > 0 && questionPaper.map((data: any, index: number) => {
                                            return (
                                                <tr>
                                                    <td className='fw-bold '>
                                                        {index + 1}
                                                    </td>
                                                    <td>
                                                        {data.examId}
                                                    </td>
                                                    <td>
                                                        {data.shiftId}
                                                    </td>
                                                    <td>
                                                        {data._id}
                                                    </td>
                                                    <td>
                                                        {moment(data.createdAt).format("DD/MM/YYYY hh:mm a")}
                                                    </td>
                                                    <td>
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                as={CustomToggle}
                                                                id="dropxdown-custom-components"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faEllipsisVertical}
                                                                    className="text-primary xcn-link-pointer"
                                                                />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                {/* <Dropdown.Item className="text-secondary">
                                                                    <FontAwesomeIcon className="me-2 text-danger" icon={faFilePdf} />
                                                                    Download as PDF
                                                                </Dropdown.Item>
                                                                <Dropdown.Divider /> */}
                                                                <Dropdown.Item className="text-secondary" onClick={() => handleQuestionPaperDownload(data._id)}>
                                                                    <FontAwesomeIcon className="me-2 text-success" icon={faFileExcel} />
                                                                    Download as Excel
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className="text-secondary" onClick={() => setSelectedQp(data._id)}>
                                                                    <FontAwesomeIcon className="me-2" icon={faUserCircle} />
                                                                    Add User
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>

            <BasicModal
                show={selectedQp !== ''}
                setShow={() => setSelectedQp('')}
                size="sm"
                heading="Add User"
                body={
                    <>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Enter User Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter User Email"
                                        value={userDetails.email}
                                        onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Enter User Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter User Name"
                                        value={userDetails.name}
                                        onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="m-2 d-flex justify-content-end gap-2">
                            <Button size="sm" variant="secondary" onClick={() => setSelectedQp('')}>
                                Close
                            </Button>
                            <Button size="sm" variant="primary" onClick={handleAddUser}>
                                Add User
                            </Button>
                        </div>
                    </>
                }
            />
        </>
    )
}