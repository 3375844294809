import React, { useEffect } from 'react';
import { Badge, Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFloppyDisk, faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import ViewService from '../../services/view.service';
import UploadService from '../../services/upload.service';
import ViewVerificationIssues from './ViewQuestion/Verification.issue';
import ViewQuestion from './ViewQuestion/ViewQuestion';
import PreviewModal from '../../Modal/preview.modal';
import { AiQuestionService } from '../../services/aiQuestion.service';

export default function AiQuestionEdit() {

    const params: any = useParams();

    const [questionData, setQuestionData] = useState<any>({});
    const [questionAttachments, setQuestionAttachements] = useState<any[]>([]);
    const [encData, setEncData] = useState<any>();
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>();
    const [correctAnswer, setCorrectAnswer] = useState<any>([])
    const [compCorrectAnswer, setCompCorrectAnswer] = useState<any>([])
    const [toggleQuestion, setToggleQuestion] = useState<boolean>(false)
    const [questionText, setQuestionText] = useState<any>();

    const getQuestionByQuestionId = async () => {
        await ViewService.getQuestionByQuestionId(params.languageId).then((res: any) => {
            if (res.status === 200) {
                setQuestionData(res.data);
                const newCorrectAnswers = [];
                const newCompCorrectAnswers = [];
                if (res.data?.questionType === "comprehension") {
                    for (const ans of res.data?.questions?.answers) {
                        const ans_index = res.data.questions?.options.findIndex((opt: any) => String(opt._id) === String(ans));
                        if (ans_index >= 0) {
                            newCompCorrectAnswers.push(ans_index);
                        }
                    }
                    setCompCorrectAnswer(newCompCorrectAnswers)
                } else {
                    for (const ans of res.data?.answers) {
                        const ans_index = res.data.options.findIndex((opt: any) => String(opt._id) === String(ans));
                        if (ans_index >= 0) {
                            newCorrectAnswers.push(ans_index);
                        }
                    }
                    setCorrectAnswer(newCorrectAnswers)
                }
            }
        })
    }


    useEffect(() => {
        getQuestionByQuestionId();
    }, [])

    const saveQuestion = async () => {
        if (!params.questionId) return false;

        // const explanation_payload = {
        //     audio: questionData.explanation?.audio,
        //     video: questionData.explanation?.video,
        //     image: questionData.explanation?.image,
        //     doc: questionData.explanation?.doc,
        //     text: questionData.explanation?.text,
        //     _id: questionData.explanation?._id
        // }


        // const questionData_payload = {
        //     language: questionData.language,
        //     options: questionData.options,
        //     image: questionData.image,
        //     audio: questionData.audio,
        //     video: questionData.video,
        //     attachments: questionData.attachments,
        //     answers: correctAnswer,
        //     questionText: questionText,
        //     explanation: explanation_payload,
        // }

        // const questionDataPayload = {
        //     topicId: questionData?.value ? questionData?.value : questionData.topicId._id,
        //     subjectId: questionData.subjectId._id,
        //     subTopicId: questionData.subTopicId,
        //     questionType: questionData.questionType,
        //     examCategory: questionData.examCategory,
        //     optionType: questionData.optionType,
        //     difficulty: questionData.difficulty,
        //     questionData: [questionData_payload]
        // };

        let payload = {
            options: questionData?.options,
            question: questionData?.question,
            answers: questionData?.answers,
        }

        toast.promise(
            AiQuestionService.updateAIQuestionLanguage(params.languageId, payload),
            {
                loading: 'Uploading...',
                success: (res) => {
                    if (res.status === 200) {
                        getQuestionByQuestionId();
                    }
                    return "Question Updated Successfully";
                },
                error: "Unable to upload this question.",
            }
        );
    }


    // const getEncMediaImage = async (link: string) => {
    //     await ViewService.getEncMultimedia(link).then((res => {
    //         if (res.status === 200) {
    //             setEncData(res.data);
    //         }
    //     }))
    // }

    // const deleteAttachment = (index: any) => {
    //     setQuestionData((obj: any) => ({
    //         ...obj,
    //         attachments: obj.attachments.filter(
    //             (_: any, i: number) => i !== index
    //         ),
    //     }));
    // };


    return (
        <>
            <div>
                <div className='mt-3'>
                    <div className='d-flex justify-content-start align-items-center'>
                        <h5 className="fw-bold">Edit Question <span className="text-muted">(#{questionData._id})</span></h5>

                    </div>
                    <div>
                        {questionData.isVerified ? <Badge bg="success">Verified</Badge> : <Badge bg="warning">Not Verified</Badge>}
                    </div>
                    <div className='mt-4'>
                        {questionData ?
                            <>
                                {questionData.verification_issues && questionData.verification_issues.length > 0 &&
                                    <ViewVerificationIssues issues={questionData.verification_issues} reloadQuestionData={getQuestionByQuestionId} />}

                                <div className="mt-3">
                                    {/* <Card className="reg-card box-shadow">
                                        <Card.Body>
                                            <h6 className="mt-2 fw-bold text-secondary">Question Image Attachments</h6>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Label className="xcn-text-12 text-secondary">Question Image Attachemnts</Form.Label>
                                                    <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={handleQuestionAttachements} />
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Label className="xcn-text-12 text-secondary">Uploads</Form.Label>
                                                    {questionData && questionData.attachments && questionData.attachments.length > 0 ? questionData.attachments.map((attachment: any, index: number) => {
                                                        return (
                                                            <div>
                                                                <p className="text-muted xcn-text-10 fw-bold" >Image {index + 1} <FontAwesomeIcon icon={faEye} className="ms-2" onClick={() => { getEncMediaImage(attachment); setShowPreviewModal(true) }} />
                                                                    <span><FontAwesomeIcon icon={faTrash} className="ms-3 text-danger" onClick={() => deleteAttachment(index)} /></span>
                                                                </p>
                                                            </div>
                                                        )
                                                    }) : <p className="xcn-text-12 text-secondary">No attachents uploaded</p>}
                                                    <PreviewModal
                                                        type="image"
                                                        show={showPreviewModal}
                                                        onHide={() => setShowPreviewModal(false)}
                                                        image={encData}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card> */}
                                    <Card className="reg-card box-shadow">
                                        <Card.Body>
                                            <h6 className="mt-2 fw-bold text-secondary">Question </h6>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Label className="xcn-text-12 text-secondary">Text:</Form.Label>
                                                    <Form.Control type="text" as="textarea" value={questionData?.question} onChange={(e) => setQuestionData({ ...questionData, question: e.target.value })} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='mt-3'>
                                    <Card className="reg-card box-shadow">
                                        <Card.Body>
                                            <h6 className="mt-2 fw-bold text-secondary">Question Data</h6>
                                            {/* <ViewQuestion
                                                questionData={questionData}
                                                setQuestionData={setQuestionData}
                                                saveQuestion={saveQuestion}
                                                correctAnswer={correctAnswer}
                                                setCorrectAnswer={setCorrectAnswer}
                                                setQuestionText={setQuestionText}
                                                compCorrectAnswer={compCorrectAnswer}
                                                setCompCorrectAnswer={setCompCorrectAnswer}
                                            /> */}
                                            <RenderOptionsData questionData={questionData} setQuestionData={setQuestionData} />
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className="d-flex justify-content-center align-items-center mt-3">
                                    <Button variant="primary" size="sm" onClick={saveQuestion}>
                                        <FontAwesomeIcon icon={faFloppyDisk} /> Save Question and Send for Re-verification
                                    </Button>
                                </div>
                            </>
                            : <>Loading Question Please Wait..</>
                        }
                    </div>
                </div>

            </div>
        </>
    )
}

interface IProps {
    questionData: any
    setQuestionData: any
}
const RenderOptionsData = (props: IProps) => {
    const addOption = () => {
        let currentOptionsData = props.questionData.options || [];
        currentOptionsData.push({});
        props.setQuestionData({ ...props.questionData, options: currentOptionsData });
    }

    const deleteOption = () => {
        let currentOptionsData = props.questionData.options || [];
        currentOptionsData.pop();
        props.setQuestionData({ ...props.questionData, options: currentOptionsData });
    }

    const handleCorrectAnswer = (checked: any, id: string) => {
        if (checked) {
            props.setQuestionData({ ...props.questionData, answers: [...props.questionData?.answers, id] });
        } else {
            props.setQuestionData({ ...props.questionData, answers: props.questionData?.answers.filter((data: any) => data != id) });
        }
    };

    const handleUpdateOptions = (e: any, index: number) => {
        let currentOptionsData = props.questionData.options;
        currentOptionsData[index].value = e.target.value;
        props.setQuestionData({ ...props.questionData, options: currentOptionsData });
    }

    return (
        <>
            <div className='d-flex justify-content-between align-items-center'>
                <div></div>
                <div>
                    {props.questionData.questionType != "boolean" && props.questionData.options ?
                        <>
                            <FontAwesomeIcon icon={faPlus} className='text-success me-2 xcn-link-pointer' size="sm" onClick={addOption} />
                            <FontAwesomeIcon icon={faMinus} className='text-danger xcn-link-pointer' size="sm" onClick={deleteOption} />
                        </> : " "}
                </div>
            </div>
            <div>
                {props.questionData && props.questionData.options && props.questionData.options.map((data: any, index: number) => {
                    return (
                        <div className="d-flex">
                            <div className="me-3">
                                <Form.Check className="fs-3" checked={props.questionData?.answers.includes(data?._id)} onChange={(e: any) => handleCorrectAnswer(e.target.checked, data?._id)} />
                            </div>
                            <FloatingLabel
                                controlId={"option" + index}
                                label={"Option " + (index + 1)}
                                className="w-100 mb-3"
                            >
                                <Form.Control type="text" value={data.value} onChange={(e: any) => handleUpdateOptions(e, index)} disabled={props.questionData.questionType == "boolean" ? true : false} />
                            </FloatingLabel>
                        </div>

                    )
                })}
            </div>
        </>
    )
}