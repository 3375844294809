import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { StatCard } from '../StatCard';
import { IChartItem } from '../LineChart';

interface IProps {
    aiQuestionStats: any
    questionStats: any
}

export const ActivityMetrics: React.FC<IProps> = ({ aiQuestionStats, questionStats }) => {

    function getGrowthPercent(data: IChartItem[]) {
        let growth = 0;
        if (data?.length > 1) {
            const lastItem = data[data.length - 1]?.count;
            const prevItem = data[data.length - 2]?.count;
            growth = ((lastItem - prevItem) / (prevItem || 1)) * 100;
        }
        return growth > 0 ? `+${growth.toFixed(2)}%` : `-${growth.toFixed(2)}%`;
    }
    return (
        <>
            <Row className="g-4 mb-4">
                <Col md={6}>
                    <StatCard
                        title="Monthly Questions Added Activity"
                        value={getGrowthPercent(questionStats?.monthly?.question)}
                        chartData={questionStats?.monthly?.question}
                    />
                </Col>
                <Col md={6}>
                    <StatCard
                        title="Monthly Questions Added Activity"
                        value={getGrowthPercent(questionStats?.monthly?.verified)}
                        chartData={questionStats?.monthly?.verified}
                    />
                </Col>
            </Row>
        </>
    );
};