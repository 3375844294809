import { Card } from "react-bootstrap";
import { AiQCardTable } from "./CardTable";
import QuestionCardLoader from "../../components/Loaders/QuestionCard.loader";

interface IQuestionCard {
    data: any,
    reload?: any,
    loading?: boolean
}

export default function QuestionCard(props: IQuestionCard) {

    if (props.loading) {
        return (
            <>
                <QuestionCardLoader />
                <QuestionCardLoader />
                <QuestionCardLoader />
            </>
        )
    }

    return (
        <>
            {props.data && props.data.length > 0 ? props.data.map((data: any) => {
                return (
                    <Card className="mt-3 shadow-lg">
                        <Card.Body>
                            <Card className="shadow-sm">
                                <Card.Body>
                                    <div className="d-flex align-items-center gap-2">
                                        <div className="d-flex align-items-center">
                                            <p className="fw-bold">Difficulty:</p>
                                            <p className="ms-2">{data?.questionsData?.difficulty}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="fw-bold">Subject:</p>
                                            <p className="ms-2">{data?.questionsData?.subjectId?.name}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="fw-bold">Topic:</p>
                                            <p className="ms-2">{data?.questionsData?.topicId?.name}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="fw-bold">Internal Id:</p>
                                            <p className="ms-2">{data?.questionsData?._id}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <p className="fw-bold">Question:</p>
                                        <p className="ms-2">{data?.questionLanguage?.[0]?.question}</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <p className="fw-bold">Created By:</p>
                                        <p className="ms-2">{data?.questionsData?.createdBy?.username || data?.questionsData?.createdBy?.name}</p>
                                    </div>
                                </Card.Body>
                            </Card>

                            <AiQCardTable
                                tableData={data.questionLanguage}
                                reload={props.reload}
                                questionId={data?.questionsData?._id}
                            />
                        </Card.Body >
                    </Card >
                )
            }) :
                <>
                    <p className="text-center">No Data Found</p>
                </>
            }
        </>
    )
}