import React, { useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { RolesContext } from "../../Routes/Private.context";
import { bnkRoutes } from "../../Routes/bnkRoutes";
import { Accordion, Button, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Sidebar.css";
import { BsDot } from "react-icons/bs";
// import { GoDotFill } from "react-icons/go";

export default function BnkSidebar() {
    const location = useLocation();
    const { roles } = useContext(RolesContext);
    const navigate = useNavigate();

    const checkIfRouteAllowed = (routeRole: string) => {
        return roles?.some((data: any) => data.role === routeRole && data.allowed) || false;
    };

    // const isActive = (path: string) => location.pathname.includes(path);
    const isActive = (path: string) => {
        const segments = location.pathname.split('/').filter(Boolean);
        const lastSegment = segments[segments.length - 1];
        return lastSegment === path;
    };

    return (
        <div className="d-flex flex-column text-white vh-100 p-3 sidebar-bg" style={{ width: '250px' }}>
            <div className="mb-2 ms-3">Nixbnk</div>

            <Nav className="flex-column mt-3">
                <Accordion className="custom-accordion">
                    {bnkRoutes.map((route: any, index: number) => {
                        if (route.navbarShow === false) {
                            return null;
                        }

                        return route.children ? (
                            <Accordion.Item eventKey={index.toString()} key={route.path} style={{ background: "transparent" }}>
                                <Accordion.Header className={`d-flex align-items-center me-2 ${isActive(route.path) ? 'active' : 'inactive'} `}>
                                    {route.icon && <route.icon className="sidebar-icon me-3" />}
                                    <span style={{ fontSize: 14 }}>
                                        {route.name}
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body className="p-0">
                                    {route.children.map((subRoute: any) => {
                                        // if (!checkIfRouteAllowed(subRoute.role)) {
                                        //     return null;
                                        // }
                                        return (
                                            <Nav.Item key={subRoute.path}>
                                                <Nav.Link
                                                    as={'div'}
                                                    className={`d-flex align-items-center gap-3 ps-4 ${isActive(subRoute.path) ? 'active' : 'inactive'}`}
                                                    disabled={!checkIfRouteAllowed(subRoute.role)}
                                                    onClick={() => {
                                                        if (checkIfRouteAllowed(subRoute.role)) {
                                                            navigate(`/${route.path}/${subRoute.path}`);
                                                        }
                                                    }}
                                                >
                                                    {subRoute.icon && <subRoute.icon className="sidebar-icon me-3" />}
                                                    <span className="" style={{ fontSize: 12 }}>{subRoute.name}</span>
                                                    <span>

                                                        {!checkIfRouteAllowed(subRoute?.role) &&
                                                            <OverlayTrigger
                                                                placement="auto-end"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={<Tooltip id="button-tooltip">
                                                                    You're not allowed for this role!!
                                                                </Tooltip>}
                                                            >
                                                                <span><BsDot className="text-danger" style={{ cursor: "pointer" }} size={20} /></span>
                                                            </OverlayTrigger>
                                                        }
                                                    </span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        );
                                    })}
                                </Accordion.Body>
                            </Accordion.Item>
                        ) : (
                            <Nav.Item key={route.path} className="d-flex align-items-center ms-1">
                                <Nav.Link
                                    as="div"
                                    // to={`/${route.path}`}
                                    className={`d-flex align-items-center gap-3 ${isActive(route.path) ? 'active' : 'inactive'}`}
                                    disabled={!checkIfRouteAllowed(route.role)}
                                    onClick={() => {
                                        if (checkIfRouteAllowed(route.role)) {
                                            navigate(route.path);
                                        }
                                    }}
                                >
                                    {route.icon && <route.icon className="sidebar-icon" />}
                                    <span style={{ fontSize: 14 }}>
                                        {route.name}
                                    </span>
                                    {!checkIfRouteAllowed(route.role) &&
                                        <OverlayTrigger
                                            placement="auto-end"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">
                                                You're not allowed for this role!!
                                            </Tooltip>}
                                        >
                                            <span><BsDot className="text-danger" style={{ cursor: "pointer" }} size={20} /></span>
                                        </OverlayTrigger>
                                    }
                                </Nav.Link>
                            </Nav.Item>
                        );
                    })}
                </Accordion>
            </Nav>
        </div>
    );
}
