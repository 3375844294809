import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import UserProfileService from '../../services/userprofile.service';
import PublicService from '../../services/public.service';
import { TranslatorService } from '../../services/translator.service';
import toast from 'react-hot-toast';
import { ObjectHelper } from '../../helper/Object.helper';
import Questions from './Questions';
import { makeParams } from '../../api/make.request';
import { AiQuestionService } from '../../services/aiQuestion.service';

export default function AiQuestionsIndex() {
    const [topicsData, setTopicsData] = useState<any>();
    const [subjects, setSubjects] = useState<any[]>();
    const [languages, setLanguages] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [gradeOptions, setGradeOptions] = useState<any[]>([]);
    const [questionToughnessOptions, setQuestionToughnessOptions] = useState<any[]>([]);

    const [selectedQuestionSubject, setSelectedQuestionSubject] = useState<any>();
    const [selectTopicId, setSelectTopicId] = useState<any>();
    const [selectedLanguage, setSelectedLanguage] = useState<any>();
    const [selectedGrade, setSelectedGrade] = useState<any>();
    const [selectedToughness, setSelectedToughness] = useState<any>();
    const [additionalComments, setAdditionalComments] = useState<string>('');
    const [questionCount, setQuestionCount] = useState({
        mcq: 0,
        shortAnswer: 0,
        descriptive: 0
    });

    const [questionData, setQuestionData] = useState<any[]>([]);
    const [selectedApi, setSelectedApi] = useState<IOption>(api_options[0]);
    const [api2Topic, setApi2Topic] = useState<string>("");


    const getTopicsAndSubTopics = async (selectedSubjectId?: any) => {
        try {
            const res = await UserProfileService.getAllSubjects();
            if (res.status === 200) {
                const selectedSubject = res.data.find((data: any) => data._id === selectedSubjectId);

                if (selectedSubject) {
                    const topics = selectedSubject.topics.map((topic: any) => ({
                        label: topic.name,
                        value: topic._id,
                    }));
                    setTopicsData(topics);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getAllLanguage = async () => {
        await PublicService.getLanguage().then((res) => {
            if (res.status === 200) {
                console.log(res.data)
                setLanguages(Object.values(res.data).map((data: any) => {
                    return {
                        label: data,
                        value: data
                    }
                }))
            }
        })
    }

    const getCompanySubject = async () => {
        await TranslatorService.getCompanySubject().then((res) => {
            if (res.status === 200) {
                const subjects = res.data.map((data: any) => {
                    return {
                        label: data.name,
                        value: data._id,
                    };
                });
                setSubjects(subjects);
            }
        })
    }

    const getAiEnums = async () => {
        await AiQuestionService.getAiEnums().then((res) => {
            if (res.status === 200) {
                // console.log(res.data)
                setGradeOptions(res?.data?.gradeOptions || []);
                setQuestionToughnessOptions(res?.data?.questionToughnessOptions || []);
            }
        })
    }


    async function handleCreateQuestion() {
        if (!selectedQuestionSubject || !selectTopicId || !selectedLanguage || !selectedGrade || !selectedToughness) {
            toast.error("Please select all required fields")
            return
        }

        if (!questionCount.mcq && !questionCount.shortAnswer && !questionCount.descriptive) {
            toast.error("Please select atleast one type of question")
            return
        }

        let payload: any = {
            subject: selectedQuestionSubject?.label,
            subTopic: selectTopicId?.label,
            mcq: questionCount.mcq,
            shortAnswer: questionCount.shortAnswer,
            descriptive: questionCount.descriptive,
            grade: selectedGrade?.label,
            toughness: selectedToughness?.label,
            language: selectedLanguage?.label,
            additionalComment: additionalComments,
        }

        if (selectedApi?.value === 'api-2') {
            payload['apiTwo'] = true;
            payload['topic'] = api2Topic;
        }

        payload = ObjectHelper.removeEmpty(payload);
        setLoading(true);

        await AiQuestionService.createAIQuestion(payload)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Question created successfully")
                    setLoading(false);
                    setQuestionData(res.data?.questionsData || [])
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(err.response.data)
            })
            .finally(() => setLoading(false))

    }

    async function handleSaveQuestions(questions: any[]) {
        let params = makeParams([
            { index: 'subject', value: selectedQuestionSubject?.value },
            { index: 'topic', value: selectTopicId?.value },
            { index: 'language', value: selectedLanguage?.value },
            { index: 'grade', value: selectedGrade?.value },
            { index: 'toughness', value: selectedToughness?.value },
        ]);

        if (!questions || !questions.length) {
            toast.error("Please select atleast one question")
            return
        }

        await AiQuestionService.saveAiQuestion(params, { questions })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Questions saved successfully");
                    setQuestionData([]);
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(err.response.data)
            })
    }

    useEffect(() => {
        getCompanySubject();
        getAllLanguage();
        getAiEnums();
    }, []);

    useEffect(() => {
        if (selectedQuestionSubject?.value) {
            getTopicsAndSubTopics(selectedQuestionSubject?.value);
        }
    }, [selectedQuestionSubject?.value]);

    return (
        <>
            <div>
                <Card className="shadow-sm p-3">
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4>AI Questions</h4>
                        </div>
                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className='text-muted '>Select API<span className='text-danger'>*</span></Form.Label>
                                    <Select
                                        placeholder="Select API..."
                                        options={api_options}
                                        onChange={(e: any) => setSelectedApi(e)}
                                        className='m-1'
                                        isClearable
                                        isSearchable
                                        value={selectedApi}
                                    />
                                </Form.Group>
                            </Col>
                            {
                                selectedApi?.value === 'api-2' &&
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label className='text-muted'>Topic</Form.Label>
                                        <Form.Control type="text" value={api2Topic} onChange={(e) => setApi2Topic(e.target.value)} placeholder='Topic, welding etc' />
                                    </Form.Group>
                                </Col>
                            }
                        </Row>
                        <Row className='mt-2'>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className='text-muted '>Select Subject <span className='text-danger'>*</span> </Form.Label>
                                    <Select
                                        placeholder="Select Subject..."
                                        options={subjects}
                                        onChange={e => setSelectedQuestionSubject(e)}
                                        isClearable
                                        isSearchable
                                        className='m-1'
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className='text-muted '>Select Sub-Topic <span className='text-danger'>*</span></Form.Label>
                                    <Select
                                        placeholder="Select Topic..."
                                        options={topicsData}
                                        onChange={e => setSelectTopicId(e)}
                                        isClearable
                                        className='m-1'
                                        isSearchable
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className='text-muted '>Select Grade <span className='text-danger'>*</span></Form.Label>
                                    <Select
                                        placeholder="Select Grade..."
                                        options={gradeOptions}
                                        onChange={e => setSelectedGrade(e)}
                                        isClearable
                                        className='m-1'
                                        isSearchable
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className='text-muted '>Select Language <span className='text-danger'>*</span></Form.Label>
                                    <Select
                                        placeholder="Select Language..."
                                        options={languages}
                                        onChange={e => setSelectedLanguage(e)}
                                        isClearable
                                        className='m-1'
                                        isSearchable
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className='text-muted '>Select Toughness <span className='text-danger'>*</span></Form.Label>
                                    <Select
                                        placeholder="Select Toughness..."
                                        options={questionToughnessOptions}
                                        onChange={e => setSelectedToughness(e)}
                                        className='m-1'
                                        isClearable
                                        isSearchable
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className='text-muted mt-2'>Number of MCQ Questions</Form.Label>
                                    <Form.Control type="number" value={questionCount.mcq} onChange={(e) => setQuestionCount({ ...questionCount, mcq: Number(e.target.value) })} />
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className='text-muted mt-2'>Number of Short Answer Questions</Form.Label>
                                    <Form.Control type="number" value={questionCount.shortAnswer} onChange={(e) => setQuestionCount({ ...questionCount, shortAnswer: Number(e.target.value) })} />
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className='text-muted mt-2'>Number of Descriptive Questions</Form.Label>
                                    <Form.Control type="number" value={questionCount.descriptive} onChange={(e) => setQuestionCount({ ...questionCount, descriptive: Number(e.target.value) })} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='mt-2'>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label className='text-muted'>Additional Comment</Form.Label>
                                    <Form.Control as={"textarea"} rows={3} type="text" value={additionalComments} onChange={(e) => setAdditionalComments(e.target.value)} placeholder='Additional Comments' />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-end align-items-center mt-2">
                            <Button variant="primary" onClick={handleCreateQuestion} disabled={loading} >
                                {
                                    loading ? <span>Loading...<Spinner animation="border" size='sm' /></span>
                                        : "Generate Questions"
                                }
                            </Button>
                        </div>
                    </Card.Body>
                </Card>


                {
                    loading ?
                        <Card className="my-3 shadow">
                            <Card.Header>
                                <h5 className="text-primary">Questions</h5>
                            </Card.Header>
                            <Card.Body>
                                <span>Generating Questions...<Spinner animation="border" size='sm' /></span>
                            </Card.Body>
                        </Card>
                        :
                        <Questions questions={questionData} handleSaveQuestion={handleSaveQuestions} />
                }
            </div>
        </>
    )
};

interface IOption {
    label: string;
    value: string;
}

const api_options: IOption[] = [
    { label: "Api-1", value: "api-1" },
    { label: "Api-2", value: "api-2" },
]