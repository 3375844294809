import React, { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { StatCard } from '../StatCard';
import { AgCharts } from "ag-charts-react";
interface IProps {
    stats: any
    aiQuestionStats: any
    questionStats: any
}
export const StatsOverview: React.FC<IProps> = ({ stats, aiQuestionStats, questionStats }) => {

    const questionStatsData:any = {
        Pending: questionStats?.pending,
        Total: questionStats?.total,
        Verified: questionStats?.verified,
    };

    const chartData = Object.entries(questionStatsData).map(([key, value]) => ({
        asset: key,
        amount: value,
    }));

    const [options] = useState<any>({
        data: chartData,
        title: {
            text: "Question Statistics",
        },
        series: [
            {
                type: "donut",
                calloutLabelKey: "asset",
                angleKey: "amount", 
                innerRadiusRatio: 0.7,
            },
        ],
    });

    return (
        <Row className="mb-3">
            <h3>Statistics</h3>
            <Col md={6}>
                <Row>
                    <Col md={6}>
                        <Card
                            style={{
                                backgroundColor: "#f9f9f9",
                                borderRadius: "12px",
                                border: "none",
                            }}>
                            <Card.Body>
                                <Card className="mb-3" style={{ border: "none", outline: "none" }}>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between'>
                                            <p className="text-muted" style={{ fontSize: 14, fontWeight: "600" }}>Active Users</p>
                                            <span className="fw-bold">{stats?.active}/{stats?.total}</span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3" style={{ border: "none", outline: "none" }}>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between'>
                                            <p className="text-muted" style={{ fontSize: 14, fontWeight: "600" }}>QA Users</p>
                                            <span className="fw-bold">{stats?.qa}</span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3" style={{ border: "none", outline: "none" }}>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between'>
                                            <p className="text-muted" style={{ fontSize: 14, fontWeight: "600" }}>AI-QA Users</p>
                                            <span className="fw-bold">{stats?.aiQA}</span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3" style={{ border: "none", outline: "none" }}>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between'>
                                            <p className="text-muted" style={{ fontSize: 14, fontWeight: "600" }}>Upload Users</p>
                                            <span className="fw-bold">{stats?.upload}</span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card
                            style={{
                                backgroundColor: "#f9f9f9",
                                borderRadius: "12px",
                                // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                border: "none",
                            }}>
                            <Card.Body>
                                <Card className="mb-3" style={{ border: "none", outline: "none" }}>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between'>
                                            <p className="text-muted" style={{ fontSize: 14, fontWeight: "600" }}>Total Ai Questions</p>
                                            <span className="fw-bold">{aiQuestionStats?.total}</span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3" style={{ border: "none", outline: "none" }}>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between'>
                                            <p className="text-muted" style={{ fontSize: 14, fontWeight: "600" }}>Verified AI-Questions</p>
                                            <span className="fw-bold">{aiQuestionStats?.verified}</span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3" style={{ border: "none", outline: "none" }}>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between'>
                                            <p className="text-muted" style={{ fontSize: 14, fontWeight: "600" }}>Pending AI-Questions</p>
                                            <span className="fw-bold">{aiQuestionStats?.pending}</span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3" style={{ border: "none", outline: "none" }}>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between'>
                                            <p className="text-muted" style={{ fontSize: 14, fontWeight: "600" }}>Not-Verified AI-Questions</p>
                                            <span className="fw-bold">{aiQuestionStats?.total - aiQuestionStats?.verified}</span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col md={6}>
                <Card
                    style={{
                        backgroundColor: "#f9f9f9",
                        borderRadius: "12px",
                        border: "none",
                    }}>
                    <Card.Body>
                        <AgCharts 
                        options={options}
                        style={{ height: 340 }}
                         />
                    </Card.Body>
                </Card>
            </Col>


        </Row>
    );
};