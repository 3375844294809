import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Row } from 'react-bootstrap'
import { BsArrowLeft } from 'react-icons/bs'
import Select from 'react-select';
import { AiQuestionService } from '../../services/aiQuestion.service';
import { useParams } from 'react-router-dom';
import AIQuestionsData from '../QA/AIQuestionsData';

export default function AiQuestionView() {
    const [questionData, setQuestionData] = useState<any>();
    const [edit, setEdit] = useState<boolean>(false);
    const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState<any>();
    const [difficultyLevel, setDifficultyLevel] = useState<any[]>();
    const params = useParams();

    function updateQuestionDifficulty() {
        setEdit(false)
    }

    async function getQuestionDetails() {
        await AiQuestionService.getQuestionByQuestionId(params.languageId)
            .then((res) => {
                if (res.status === 200) {
                    setQuestionData(res.data?.questionData);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getQuestionDetails()
    }, []);
    return (
        <>
            <Row>
                <Col>
                    <span
                        className='d-flex align-items-center xcn-text-15  mb-2 mt-2'
                        onClick={() => window.history.back()}
                        style={{ cursor: 'pointer' }}
                    >
                        <BsArrowLeft /> {" "}
                        <span className='fw-bold xcn-text-15 text-muted'>Back</span>
                    </span>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <Card className="reg-card box-shadow mt-3 mb-4">
                        <Card.Body>
                            {questionData?.isVerified ? <Badge bg="success">Verified</Badge> : <Badge bg="warning">Not Verified</Badge>}
                            <Row>
                                <Col md={6}>
                                    {edit ? <div className="d-flex align-items-center">
                                        <span className='fw-bold xcn-text-15 text-muted'>Difficulty: </span>
                                        <div className="ms-2" style={{ width: "50%" }}>
                                            <Select
                                                value={selectedDifficultyLevel ? [{ label: selectedDifficultyLevel, value: selectedDifficultyLevel }] : [{ label: questionData?.dificulty, value: questionData?.dificulty }]}
                                                options={difficultyLevel}
                                                onChange={(e: any) => setSelectedDifficultyLevel(e.value)}
                                            />
                                        </div>
                                        <Button size="sm" variant="secondary" className="ms-2 py-2 px-3" onClick={() => updateQuestionDifficulty()} >Save</Button>
                                    </div> :
                                        <div className="d-flex">
                                            <span className='fw-bold xcn-text-15 text-muted'>Difficulty: </span>
                                            <span className='fw-bold xcn-text-15 ms-2'>{questionData && questionData.dificulty ? questionData.dificulty : "0"}</span>
                                            <FontAwesomeIcon icon={faPencil} className='fw-bold text-muted xcn-text-15 ms-2 text-center' style={{ cursor: "pointer" }} onClick={() => setEdit(true)} />
                                        </div>
                                    }

                                    <div>
                                        <span className='fw-bold xcn-text-15 text-muted'>Frequency Used: </span>
                                        <span className='fw-bold xcn-text-15'>{questionData && questionData.frequencyUsed ? questionData.frequencyUsed : "0"}</span>
                                    </div>
                                    <div>
                                        <span className='fw-bold xcn-text-15 text-muted'>Subject: </span>
                                        <span className='fw-bold xcn-text-15'>{questionData && questionData.subject ? questionData.subject : "--"}</span>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <span className='fw-bold xcn-text-15 text-muted'>Language: </span>
                                        <span className='fw-bold xcn-text-15'>{questionData && questionData.language ? questionData.language : "--"}</span>
                                    </div>
                                    <div>
                                        <span className='fw-bold xcn-text-15 text-muted'>Option Type: </span>
                                        <span className='fw-bold xcn-text-15'>{questionData && questionData.optionType ? questionData.optionType : "--"}</span>
                                    </div>
                                    <div>
                                        <span className='fw-bold xcn-text-15 text-muted'>Question Type: </span>
                                        <span className='fw-bold xcn-text-15'>{questionData && questionData.questionType ? questionData.questionType : "--"}</span>
                                    </div>
                                </Col>
                            </Row>
                            <AIQuestionsData questionData={questionData} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}