import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { MdLogout, MdOutlineManageAccounts } from 'react-icons/md';
import CustomToggle from '../../Menu/CustomMenu';
import { CgProfile } from "react-icons/cg";

const Topbar = () => {

    const navigate = useNavigate();

    

    const handleLogout = () => {
        localStorage.removeItem("bnkauthkey")
        navigate("/login")
    }


    return (
        <Navbar className="topbar shadow-sm" expand="lg">
            <Container>
                <Navbar.Brand>
                    <h6>Welcome Back!</h6>
                </Navbar.Brand>
                <div className="mx-2">
                    <Dropdown align="end" drop="down">
                        <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                            className="px-5"
                        >
                            <CgProfile size={25} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="px-3" style={{ width: "250px" }}>
                            {/* <div className='ms-3'>
                                <h6 className="">
                                    {userData?.firstName} {" "} {userData?.lastName}
                                </h6>
                                <span className="text-secondary fs-12" style={{ marginTop: -10 }}>{userData?.email}</span>
                            </div>

                            <Dropdown.Divider /> */}
                            <Dropdown.Item
                                className="text-secondary align-items-center d-flex fs-14"
                                onClick={() => { }}
                            >
                                <MdOutlineManageAccounts size={20} className="me-1" /> Account
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                                className="text-secondary align-items-center d-flex fs-14"
                                onClick={handleLogout}
                            >
                                <MdLogout size={18} className="me-1 text-danger" />
                                <span className="text-danger">
                                    Logout
                                </span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Container>
        </Navbar>
    );
};

export default Topbar;
