import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import UserProfileService from '../services/userprofile.service';
import { QAContext, RolesContext } from './Private.context';
import BnkSidebar from '../components/BnkSidebar/BnkSidebar';
import Topbar from '../components/Topbar/Topbar';
import "./Private.index.css"

export default function PrivateRoutes() {

    const [roles, setRoles] = useState<any>([]);
    const [qaData, setQaData] = useState<any>();
    const [pageAllowed, setPageAllowed] = useState<boolean>(true);
    const location = useLocation();


    const getUserRoles = async () => {
        await UserProfileService.getUserRoles().then(res => {
            if (res.status === 200) {
                setRoles(res.data)
            }
        })
    }

    function checkIfPathNameIsInRoles() {
        if (!roles || roles.length === 0) return;
        let role = roles.find((data: any) => location.pathname.includes(data.role) && data.allowed === true);
        if (!role) {
            setPageAllowed(false);
        } else {
            setPageAllowed(true);
        }
    }

    useEffect(() => {
        getUserRoles();
    }, []);

    useEffect(() => {
        checkIfPathNameIsInRoles();
    }, [roles, location.pathname]);

    // console.table(roles);
    return (
        <>
            <RolesContext.Provider value={{ roles, getUserRoles }}>
                <QAContext.Provider value={{ qaData, setQaData }}>
                    <div className="private-layout">
                        <div className="sidebar">
                            <BnkSidebar />
                        </div>
                        <div className="main-content">
                            <Topbar />
                            <div className="container py-3">
                                {
                                    pageAllowed ?
                                        <Outlet />
                                        :
                                        <Container className="d-flex justify-content-center align-items-center">
                                            <div className="text-center mt-5 p-5">
                                                <h1 className="display-1 fw-bold">404</h1>
                                                <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
                                                <p className="lead">
                                                    The page you’re looking for doesn’t exist.
                                                </p>
                                            </div>
                                        </Container>
                                }
                            </div>
                        </div>
                    </div>
                </QAContext.Provider>
            </RolesContext.Provider>
        </>
    )
}