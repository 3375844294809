import React from 'react';
import { Card } from 'react-bootstrap';
import { LineChart } from './LineChart';

export const StatCard: React.FC<any> = ({ title, value, total, trend, chartData }) => {
    return (
        <Card className="h-100">
            <Card.Body>
                <div className="d-flex flex-column">
                    <div className="text-muted mb-2">{title}</div>
                    <div className="d-flex align-items-baseline">
                        <h2 className="mb-0 me-2">{value}</h2>
                        {total && <span className="text-muted">/{total}</span>}
                        {trend && (
                            <span className={`ms-2 ${trend > 0 ? 'text-success' : 'text-danger'}`}>
                                {trend > 0 ? '+' : ''}{trend}%
                            </span>
                        )}
                    </div>
                    {chartData && (
                        <div className="mt-3">
                            <LineChart data={chartData} />
                        </div>
                    )}
                </div>
            </Card.Body>
        </Card>
    );
};