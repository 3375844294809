import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';
import { StatsFilters } from '../../components/stats/StatsFilters';
import { StatsOverview } from '../../components/stats/cards/StatsOverview';
import { ActivityMetrics } from '../../components/stats/cards/ActivityMetrics';
import { VerificationOverview } from '../../components/stats/VerificationOverview/VerificationOverview';
import { LeaderboardOverview } from '../../components/stats/LeaderboardOverview/LeaderboardOverview';
import ViewService from '../../services/view.service';

export const Stats: React.FC = () => {
    // const [filters, setFilters] = useState<any>({
    //     timeframe: 'all-time',
    //     people: 'all',
    //     topic: 'all',
    // });
    const [loading, setLoading] = useState<boolean>(false);
    const [stats, setStats] = useState<any>();

    async function getStatsForChats() {
        if (loading) return;
        setLoading(true);
        await ViewService.getStats2()
            .then((res) => {
                // console.log(res.data);
                setStats(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getStatsForChats();
    }, [])

    return (
        <>
            {
                loading ? <Container className="text-center mt-5"><h1>Loading...</h1></Container> :
                    <>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            {/* <h2>Reports</h2> */}
                            {/* <Button variant="outline-primary">
                                <FaDownload size={18} className="me-2" />
                                Download
                            </Button> */}
                        </div>
                        {/* <StatsFilters filters={filters} onFilterChange={setFilters} /> */}
                        <StatsOverview stats={stats?.userCount} aiQuestionStats={stats?.aiQuestionCount} questionStats={stats?.questionCount} />
                        <ActivityMetrics aiQuestionStats={stats?.aiQuestionCount} questionStats={stats?.questionCount} />
                        {/* <VerificationOverview /> */}
                        <LeaderboardOverview data={stats?.leaderboard} />
                    </>
            }
        </>
    );
};