import { urls } from "../api/api.urls";
import makeRequest, { makeParams } from "../api/make.request";

export class TranslatorService {
    static async getAllQuestion(pageNumber: number, pageSize: number, subject: any, topic: any, language: any) {
        const params = makeParams([
            {
                index: "pageNumber",
                value: pageNumber
            },
            {
                index: "pageSize",
                value: pageSize
            },
            {
                index: "subject",
                value: subject
            },
            {
                index: "topic",
                value: topic
            },
            {
                index: "language",
                value: language
            },
        ])
        return await makeRequest(urls.translator.getAllQuestion + params, "GET")
    }

    static async getQuestionByQuestionId(questionId: any) {
        return await makeRequest(urls.translator.getQuestionByQuestionId + "/" + questionId, "GET")
    }

    static async getUserAllowedLanguage() {
        return await makeRequest(urls.translator.getUserAllowedLanguage, "GET")
    }

    static async getEncMultimedia(url: any) {
        return await makeRequest(urls.translator.getEncMultimedia + "?url=" + url, "GET");
    }

    static async createAndUpdatedQuestion(questionId: any, language: any, payload: any) {
        return await makeRequest(urls.translator.createAndUpdateQuestion + "/" + questionId + "/" + language, "PUT", payload)
    }

    static async translateLanguage(payload: any) {
        return await makeRequest(urls.translator.translateLanguageData, "post", payload)
    }

    static async getCompanySubject(){
        return makeRequest(urls.translator.getCompanySubject, "get")
    }
}