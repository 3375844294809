import React, { useState } from "react";
import { Modal, Button, Table, Image } from "react-bootstrap";

interface Option {
    _id: string;
    value: string;
}

interface QuestionLanguage {
    attachments: any[];
    answers: any[];
    explaination: any[];
    isVerified: boolean;
    _id: string;
    language: string;
    options: Option[];
    image: string;
    imageText: string;
    questionId: string;
}

interface Question {
    question: {
        _id: string;
        questionType: string;
        optionType: string;
        difficulty: number;
        createdAt: string;
        updatedAt: string;
    };
    questionLanguages: QuestionLanguage[];
}

interface Section {
    sectionName: string;
    questions: Question[];
}

interface Props {
    sections: Section[];
    show: boolean;
    handleClose: () => void;
}

const QPModalNew: React.FC<Props> = ({ sections, show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Sections and Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {sections.map((section, index) => (
                    <div key={index} className="mb-4">
                        <h5 className="text-primary mb-3">{section.sectionName}</h5>
                        {section.questions.map((question, qIndex) => (
                            <div key={qIndex} className="mb-3 border-bottom pb-3">
                                <h6>Question {qIndex + 1}</h6>
                                <Table striped bordered hover responsive>
                                    <tbody>
                                        <tr>
                                            <th>Question Type</th>
                                            <td>{question.question.questionType}</td>
                                        </tr>
                                        <tr>
                                            <th>Option Type</th>
                                            <td>{question.question.optionType}</td>
                                        </tr>
                                        <tr>
                                            <th>Difficulty</th>
                                            <td>{question.question.difficulty}</td>
                                        </tr>
                                        <tr>
                                            <th>Created At</th>
                                            <td>
                                                {new Date(question.question.createdAt).toLocaleString()}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Updated At</th>
                                            <td>
                                                {new Date(question.question.updatedAt).toLocaleString()}
                                            </td>
                                        </tr>
                                        {question.questionLanguages.map((lang, langIndex) => (
                                            <React.Fragment key={langIndex}>
                                                <tr>
                                                    <th colSpan={2} className="text-center bg-light">
                                                        Language: {lang.language}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>Image</th>
                                                    <td>
                                                        <Image
                                                            src={lang.image}
                                                            alt={lang.imageText}
                                                            thumbnail
                                                            className="w-50"
                                                        />
                                                        <p>{lang.imageText}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Options</th>
                                                    <td>
                                                        <ul>
                                                            {lang.options.map((option) => (
                                                                <li key={option._id}>{option.value}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        ))}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QPModalNew;
