import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useSearchParams } from 'react-router-dom';
import TablePagination from '../../components/Pagination/Table.paginaition';
import { AiQuestionService } from '../../services/aiQuestion.service';
import { makeParams } from '../../api/make.request';
import AiQuestionCard from '../ViewQuestions/AiQuestionCard';

export default function AiView() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [questions, setQuestions] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [activeTab, setActiveTab] = useState<string>(searchParams.get('tab') || 'Verified');

    const handleTabChange = (e: any) => {
        setSearchParams({ tab: e });
        setActiveTab(e);
        localStorage.setItem("ai-tab", e)
    }


    const getAllAiQuestions = async () => {
        const params = makeParams([
            {
                index: "status",
                value: activeTab
            },
            {
                index: "pageNumber",
                value: currentPage
            },
            {
                index: "pageSize",
                value: perPage
            },

        ])
        setLoading(true)
        await AiQuestionService.getAiSavedQuestions(params).then((res) => {
            if (res.status === 200) {
                setQuestions(res.data.finalData);
                setTotalCount(res.data.totalCount)
            }
        }).catch(err => {
            console.log(err)
        })
        setLoading(false)
    }

    useEffect(() => {
        getAllAiQuestions();
    }, [activeTab, currentPage, perPage])



    return (
        <>
            <Card className="reg-card box-shadow p-3 mt-5">
                <div className='my-3'>
                    <Tabs
                        activeKey={activeTab}
                        transition={false}
                        id="justify-tab-example"
                        className="mb-3 text-center"
                        justify
                        onSelect={handleTabChange}
                    >
                        <Tab eventKey="Verified" title="Verified" tabClassName='fw-normal'>
                            <AiQuestionCard data={questions} loading={loading} />
                        </Tab>
                        <Tab eventKey="unverified" title="Not Verified" tabClassName='fw-normal'>
                            <AiQuestionCard data={questions} loading={loading} />
                        </Tab>
                    </Tabs>
                </div>
            </Card>
            <div className="text-center my-3">
                <TablePagination
                    total={totalCount}
                    currentPage={currentPage}
                    perPage={perPage}
                    handlePageChange={(e: number) => {
                        setCurrentPage(e);
                    }}
                    setPerPage={(e: number) => { setPerPage(e); }}
                />
            </div>
        </>
    )
}
