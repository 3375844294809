import { faTimesCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal } from 'react-bootstrap';

interface IBasicModal {
    show: boolean,
    setShow: any,
    body: any,
    heading?: any,
    size?: "sm" | "lg" | "xl"
}

export default function BasicModal(props: IBasicModal) {
    return (
        <Modal show={props.show} size={props.size} >
            <div className='d-flex justify-content-between align-items-center m-3'>
                <div>
                    {props.heading}
                </div>
                <FontAwesomeIcon className='xcn-link-pointer' icon={faXmark} onClick={() => props.setShow(false)} />
            </div>
            <Modal.Body>{props.body}</Modal.Body>
        </Modal>
    )
}