import { faCheck, faEllipsisVertical, faEye, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { Accordion, Card, Dropdown, Table } from "react-bootstrap"
import CustomToggle from "../../Menu/CustomMenu"
import ViewService from "../../services/view.service"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { AiQuestionService } from "../../services/aiQuestion.service"

interface ICardTable {
    tableData: any,
    reload: any,
    questionId: any
}

export default function CardTable({ tableData, reload, questionId }: ICardTable) {

    const navigate = useNavigate();
    const tab = localStorage.getItem("tab");

    const handleViewClick = (id: any) => {
        navigate(`/view/question/${questionId}/${id}`);
    };

    const deleteQuestion = async (Id: any) => {
        await ViewService.deleteQuestion(Id).then((res) => {
            if (res.status === 200) {
                toast.success("Question Deleted Successfully");
                reload();
            }
        }).catch(err => {
            toast.error("Unable to delete")
        })
    }


    return (
        // <Card className="mt-3 shadow-sm">
        //     <Card.Body>
        <Table hover className="border rounded-2 p-2 mt-3">
            <thead>
                <tr className='bg-light'>
                    <th>Sr No.</th>
                    <th>Language</th>
                    <th>Image</th>
                    <th>Audio</th>
                    <th>Video</th>
                    <th>Verified</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {tableData?.map((data: any, index: number) => {
                    return (
                        <tr>
                            <td className=''>
                                {index + 1}
                            </td>
                            <td className="xcn-text-10">
                                {data.language?.toUpperCase()}
                            </td>
                            <td className="xcn-text-10">
                                {data.image ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                            </td>
                            <td className="xcn-text-10">
                                {data.audio ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                            </td>
                            <td className="xcn-text-10">
                                {data.video ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                            </td>
                            <td className="xcn-text-10">
                                {data.isVerified ? <span className="text-success">Yes</span> : "No"}
                            </td>
                            <td className="xcn-text-10">
                                {moment(data.createdAt).format("DD/MM/YYYY hh:mm a")}
                            </td>
                            <td className="xcn-text-10">
                                {moment(data.updatedAt).format("DD/MM/YYYY hh:mm a")}
                            </td>
                            <td>
                                <Dropdown style={{ cursor: "pointer" }}>
                                    <Dropdown.Toggle
                                        as={CustomToggle}
                                        id="dropdown-custom-components"
                                    >
                                        <FontAwesomeIcon icon={faEllipsisVertical} className="text-primary" size="sm" />

                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>

                                        <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold" onClick={() => handleViewClick(data._id)}>
                                            <span className='text-primary xcn-text-10'> <FontAwesomeIcon icon={faEye} className="ms-2" />  View </span>
                                        </Dropdown.Item>

                                        <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold" onClick={() => navigate(`/view/edit/${questionId}/language/` + data._id)}>
                                            <span className="text-warning xcn-text-10"><FontAwesomeIcon icon={faPencil} className="ms-2" /> Edit</span>
                                        </Dropdown.Item>


                                        <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                            onClick={() => {
                                                deleteQuestion(data._id)
                                            }}
                                        >
                                            <span className="text-danger xcn-text-10"  ><FontAwesomeIcon icon={faTrash} className="ms-2" /> Delete</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
        //     </Card.Body>
        // </Card>
    )
}
export function AiQCardTable({ tableData, reload, questionId }: ICardTable) {

    const navigate = useNavigate();
    const tab = localStorage.getItem("tab");

    const handleViewClick = (id: any) => {
        navigate(`/view/ai-question/${questionId}/${id}`);
    };

    const deleteQuestion = async (languageId: string) => {
        await AiQuestionService.deleteQuestionLanguage(languageId).then((res) => {
            if (res.status === 200) {
                toast.success("Question Deleted Successfully");
                reload();
            }
        }).catch(err => {
            toast.error("Unable to delete")
        })
    }


    return (
        <Accordion className="mt-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    Languages
                </Accordion.Header>
                <Accordion.Body>
                    <Table hover className="border rounded-2 p-2 mt-3">
                        <thead>
                            <tr className='bg-light'>
                                <th>Sr No.</th>
                                <th>Language</th>
                                <th>Image</th>
                                <th>Audio</th>
                                <th>Video</th>
                                <th>Verified</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.map((data: any, index: number) => {
                                return (
                                    <tr>
                                        <td className=''>
                                            {index + 1}
                                        </td>
                                        <td className="xcn-text-10">
                                            {data.language?.toUpperCase()}
                                        </td>
                                        <td className="xcn-text-10">
                                            {data.image ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                                        </td>
                                        <td className="xcn-text-10">
                                            {data.audio ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                                        </td>
                                        <td className="xcn-text-10">
                                            {data.video ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "--"}
                                        </td>
                                        <td className="xcn-text-10">
                                            {data.isVerified ? <span className="text-success">Yes</span> : "No"}
                                        </td>
                                        <td className="xcn-text-10">
                                            {moment(data.createdAt).format("DD/MM/YYYY hh:mm a")}
                                        </td>
                                        <td className="xcn-text-10">
                                            {moment(data.updatedAt).format("DD/MM/YYYY hh:mm a")}
                                        </td>
                                        <td>
                                            <Dropdown style={{ cursor: "pointer" }}>
                                                <Dropdown.Toggle
                                                    as={CustomToggle}
                                                    id="dropdown-custom-components"
                                                >
                                                    <FontAwesomeIcon icon={faEllipsisVertical} className="text-primary" size="sm" />

                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>

                                                    <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold" onClick={() => handleViewClick(data._id)}>
                                                        <span className='text-primary xcn-text-10'> <FontAwesomeIcon icon={faEye} className="ms-2" />  View </span>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold" onClick={() => navigate(`/view/ai-que-edit/${questionId}/language/` + data._id)}>
                                                        <span className="text-warning xcn-text-10"><FontAwesomeIcon icon={faPencil} className="ms-2" /> Edit</span>
                                                    </Dropdown.Item>


                                                    <Dropdown.Item className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                                        onClick={() => {
                                                            deleteQuestion(data._id)
                                                        }}
                                                    >
                                                        <span className="text-danger xcn-text-10"  ><FontAwesomeIcon icon={faTrash} className="ms-2" /> Delete</span>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

    )
}