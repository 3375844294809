import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";
import makeUploadRequest from "../api/make.upload";

export default class UploadService {
    static async uploadMultimedia(formData: any) {
        return makeUploadRequest(urls.upload.uploadMultimedia, "post", formData);
    }

    static async uploadQuestion(data: any) {
        return makeRequest(urls.upload.uploadQuestion, "post", data);
    }

    static async uploadComprehensionQuestion(data: any) {
        return makeRequest(urls.upload.uploadComprehensionQuestion, "post", data);
    }


    static async uploadImagesZip(formData: any) {
        return makeUploadRequest(urls.upload.questionImagesZip, "post", formData);
    }

    static async getCompanySubject(){
        return makeRequest(urls.upload.getCompanySubject, "get")
    }

    static async getCompanyExamCategory(){
        return makeRequest(urls.upload.getCompanyExamCategory, "get")
    }
}