import { MdCategory, MdManageAccounts, MdOutlineQueryStats, MdOutlineSubject, MdSpaceDashboard } from "react-icons/md"
import { UserRoleEnum } from "../enums/role.enum"
import DashboardIndex from "../views/Dashboard/Dashboard.index"
import { FaBabyCarriage, FaBook, FaBrain, FaBullhorn, FaDownload, FaEye, FaHistory, FaKey, FaUser } from "react-icons/fa"
import UploadQuestionIndex from "../views/UploadQuestions/UploadQuestions.index"
import UploadPdfIndex from "../views/UploadPdf/uploadPdf.index"
import { GiGearHammer, GiAutoRepair } from "react-icons/gi";
import ViewIndex from "../views/ViewQuestions/View.index"
import UserViewNotVerifiedQuestion from "../views/ViewQuestions/UserViewNotVerifiedQuestion"
import View from "../views/ViewQuestions/View"
import ViewQuestionIndex from "../views/ViewQuestions/ViewQuestion/ViewQuestion.index"
import QPDownloadIndex from "../views/QpDownload/QPDownload"
import QPGenerationIndex from "../views/QPGeneration/QPGeneration.index"
import ExamCategory from "../views/ExamCategory/ExamCategory.index"
import UpdatedSubjectIndex from "../views/Subjects/Subject.index"
import ApiKeyIndex from "../views/ApiKey/ApiKey.index"
import AiQAIndex from "../views/AiQA/AiQA.index"
import TranslatorIndex from "../views/Translator/Translator.index"
import TranslatorById from "../views/Translator/TranlatorById"
import RequestsIndex from "../views/Requests/Requests.index"
import QAIndex from "../views/QA/Qa.index"
import ViewNotVerifiedQuestion from "../views/QA/ViewNotVerifiedQuestion"
import SubjectById from "../views/Subjects/SubjectById"
import { BsBookHalf, BsTranslate } from "react-icons/bs"
import UsersIndex from "../views/Users/Users.index"
import AiQuestionsIndex from "../views/AiQuestions/AiQuestions.index"
import AiQAQuestion from "../views/AiQA/AiQAQuestion"
import AiView from "../views/AiView/AiView"
import { Stats } from "../views/Stats/Stats.index"
import AiQuestionView from "../views/ViewQuestions/AiQuestionView"
import AiQuestionEdit from "../views/ViewQuestions/AiQuestionEdit"
import QPGenerationNew from "../views/QPGeneration/QPGenerationNew"


export default interface IBnkRouter {
    role: string
    path: string
    navbarShow: Boolean
    element?: any
    name: string
    icon?: any
    children?: any[]
}


export const bnkRoutes: IBnkRouter[] = [
    {
        role: UserRoleEnum.DASHBOARD,
        path: UserRoleEnum.DASHBOARD,
        name: "Dashboard",
        element: <DashboardIndex />,
        navbarShow: true,
        icon: MdSpaceDashboard
    },

    {
        role: "upload",
        path: "upload",
        navbarShow: true,
        name: "QP Upload",
        icon: FaBullhorn,
        children: [
            {
                role: UserRoleEnum.UPLOAD,
                path: "manual-upload",
                element: <UploadQuestionIndex />,
                name: "Manual Upload",
                icon: GiGearHammer
            },
            {
                role: UserRoleEnum.UPLOAD,
                path: "auto-upload",
                element: <UploadPdfIndex />,
                name: "Auto Upload",
                icon: GiAutoRepair
            },
            {
                role: UserRoleEnum.UPLOAD,
                path: "ai-upload",
                element: <AiQuestionsIndex />,
                name: "AI Upload",
                icon: FaBrain
            },
        ],
    },
    {
        role: "management",
        path: "management",
        navbarShow: true,
        name: "Management",
        icon: MdManageAccounts,
        children: [
            {
                role: UserRoleEnum.USER,
                path: "users",
                element: <UsersIndex />,
                name: "Users",
                icon: FaUser
            },
            {
                role: UserRoleEnum.USER,
                path: "api-keys",
                element: <ApiKeyIndex />,
                name: "API Keys",
                icon: FaKey
            },
        ],
    },
    {
        role: "exam-management",
        path: "exam-management",
        navbarShow: true,
        name: "Exam",
        icon: MdManageAccounts,

        children: [
            {
                role: UserRoleEnum.QP_GENERATION,
                path: UserRoleEnum.QP_GENERATION,
                name: "Create QP",
                element: <QPGenerationIndex />,
                icon: FaBook
            },
            {
                role: UserRoleEnum.QP_GENERATION,
                path: UserRoleEnum.QP_GENERATION + "/new",
                name: "Create QP New",
                element: <QPGenerationNew />,
                icon: BsBookHalf
            },
            {
                role: UserRoleEnum.EXAM_CATEGORY,
                path: UserRoleEnum.EXAM_CATEGORY,
                name: "Exam Category",
                element: <ExamCategory />,
                icon: MdCategory
            },
            {
                role: UserRoleEnum.SUBJECT,
                path: UserRoleEnum.SUBJECT,
                name: "Subject",
                element: <UpdatedSubjectIndex />,
                icon: MdOutlineSubject
            },
        ],
    },
    {
        role: UserRoleEnum.QA,
        path: UserRoleEnum.QA,
        icon: FaUser,
        name: "QA",
        navbarShow: true,
        children: [
            {
                role: UserRoleEnum.QA,
                path: UserRoleEnum.QA,
                name: "QA",
                element: <QAIndex />,
                navbarShow: true,
                icon: FaUser
            },
            {
                role: UserRoleEnum.AI_QA,
                path: UserRoleEnum.AI_QA,
                name: "AI-Qa",
                element: <AiQAIndex />,
                navbarShow: true,
                icon: MdSpaceDashboard
            },
        ]
    },
    {
        role: "view",
        path: "view",
        navbarShow: true,
        name: "View",
        icon: FaEye,
        children: [
            {
                role: UserRoleEnum.VIEW,
                path: UserRoleEnum.VIEW,
                name: "View",
                element: <ViewIndex />,
                icon: FaBook
            },
            {
                role: UserRoleEnum.VIEW,
                path: "ai-view",
                name: "AI View",
                element: <AiView />,
                icon: FaBook
            },
        ],
    },
    // {
    //     role: UserRoleEnum.VIEW,
    //     path: UserRoleEnum.VIEW,
    //     name: "View",
    //     element: <ViewIndex />,
    //     navbarShow: true,
    //     icon: FaEye
    // },
    {
        role: UserRoleEnum.VIEW,
        path: UserRoleEnum.VIEW + "/edit" + "/:questionId" + "/language" + "/:languageId",
        name: "View",
        element: <ViewQuestionIndex />,
        navbarShow: false,
        icon: MdSpaceDashboard
    },
    {
        role: UserRoleEnum.VIEW,
        path: UserRoleEnum.VIEW + "/ai-que-edit" + "/:questionId" + "/language" + "/:languageId",
        name: "View",
        element: <AiQuestionEdit />,
        navbarShow: false,
        icon: MdSpaceDashboard
    },
    {
        role: UserRoleEnum.VIEW,
        path: UserRoleEnum.VIEW + `/question` + "/:questionId" + "/:languageId",
        name: "View",
        element: <View />,
        navbarShow: false,
        icon: MdSpaceDashboard
    },
    {
        role: UserRoleEnum.VIEW,
        path: UserRoleEnum.VIEW + `/ai-question` + "/:questionId" + "/:languageId",
        name: "View",
        element: <AiQuestionView />,
        navbarShow: false,
        icon: MdSpaceDashboard
    },
    {
        role: UserRoleEnum.DOWNLOAD,
        path: UserRoleEnum.DOWNLOAD,
        name: "Dowload",
        element: <QPDownloadIndex />,
        navbarShow: true,
        icon: FaDownload
    },
    {
        role: UserRoleEnum.SUBJECT,
        path: UserRoleEnum.SUBJECT + "/:id",
        name: "Subject",
        element: <SubjectById />,
        navbarShow: false,
        icon: MdSpaceDashboard
    },
    {
        role: UserRoleEnum.TRANSLATOR,
        path: UserRoleEnum.TRANSLATOR,
        name: "Translator",
        element: <TranslatorIndex />,
        navbarShow: true,
        icon: BsTranslate
    },
    {
        role: UserRoleEnum.TRANSLATOR,
        path: UserRoleEnum.TRANSLATOR + "/:questionId",
        name: "Translator",
        element: <TranslatorById />,
        navbarShow: false,
        icon: MdSpaceDashboard
    },
    {
        role: UserRoleEnum.REQUESTS,
        path: UserRoleEnum.REQUESTS,
        name: "Logs",
        element: <RequestsIndex />,
        navbarShow: true,
        icon: FaHistory
    },
    {
        role: UserRoleEnum.QA,
        path: UserRoleEnum.QA + "/:questionId",
        name: "QA",
        element: <ViewNotVerifiedQuestion />,
        navbarShow: false,
        icon: MdSpaceDashboard
    },
    {
        role: UserRoleEnum.AI_QA,
        path: UserRoleEnum.AI_QA + "/:questionId",
        name: "AI-Qa",
        element: <AiQAQuestion />,
        navbarShow: false,
        icon: MdSpaceDashboard
    },
]