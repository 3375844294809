import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Auth from './auth/Auth';
import PrivateRoutes from './Routes/Private.route';
import { IRouter, routes } from './Routes/routes';
import Login from './views/Login/Login';
import TranslatorById from './views/Translator/TranlatorById';
import IBnkRouter, { bnkRoutes } from './Routes/bnkRoutes';


function PrivateRouter({ children }: any) {
    const auth = Auth.checkAuth();
    return auth ? <PrivateRoutes /> : <Navigate to="/login" />;
}


export default function MainRouter() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<PrivateRouter />}>
                        {bnkRoutes.map((data: IBnkRouter) => {
                            return data.children ? (
                                <Route key={data.path} path={data.path}>
                                    {data.children.map((subData: IBnkRouter) => (
                                        <Route
                                            key={subData.path}
                                            path={subData.path}
                                            element={subData.element}
                                        />
                                    ))}
                                </Route>
                            ) : (
                                <Route
                                    key={data.path}
                                    path={data.path}
                                    element={data.element}
                                />
                            )
                        })}
                    </Route>
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}