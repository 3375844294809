import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext, useEffect, useState } from "react"
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap"
import Select from "react-select"
import SubjectService from "../../../services/subjects.service"
import UserProfileService from "../../../services/userprofile.service"
import { MdOutlineNavigateNext } from 'react-icons/md';
import toast from "react-hot-toast"
import QAService from "../../../services/qa.service"
import QuestionNotVerified from "../NotVerifiedTable/QuestionNotVerified"
import { stat } from "fs"
import { QAContext } from "../../../Routes/Private.context"
import Skeleton from "react-loading-skeleton"
import TablePagination from "../../../components/Pagination/Table.paginaition"


export default function SubjectSelection() {

    const { qaData, setQaData }: any = useContext(QAContext)

    const [subjects, setSubjects] = useState<any>([]);
    const [questions, setQuestions] = useState<any[]>();
    const [isTableActive, setIsTableActive] = useState<boolean>(false)
    const [topics, setTopics] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);

    const [isComprehension, setIsComprehension] = useState<boolean>(false)


    const handleSelectValue = (e: any, name: string) => {
        setQaData({ ...qaData, [name]: e })
    }


    const getCompanySubject = async() => {
        await QAService.getCompanySubject().then((res) => {
            if (res.status === 200) {
                const subjects = res.data.map((data: any) => {
                    return {
                        label: data.name,
                        value: data._id,
                    };
                });
                setSubjects(subjects);
            }
        })
    }



    // const getSubjectsforSection = async () => {
    //     await UserProfileService.getAllSubjects().then((res) => {
    //         if (res.status === 200) {
    //             setSubjects(res.data.map((sub: any) => {
    //                 return {
    //                     label: sub.name,
    //                     value: sub._id,
    //                 }
    //             }))
    //         }
    //     })
    // }

    const getAllTopics = async () => {
        if (!qaData.subject) {
            return false;
        }
        await QAService.getAllTopicsBySubject(qaData?.subject?.value).then((res) => {
            if (res.status === 200) {
                setTopics(res.data.topics.map((topic: any) => {
                    return {
                        value: topic._id,
                        label: topic.name
                    }
                }))
            }
        })
    }


    const getAllQuestions = async () => {
        if (!qaData?.subject || !qaData?.status) {
            return false;
        }
        setLoading(true)
        await QAService.getAllQuestions(qaData.subject.value, qaData.topic.value, qaData.status.value, currentPage, perPage).then((res) => {
            if (res.status === 200) {
                if (isComprehension) {
                    setQuestions(res.data.compFinalData)
                    setTotalCount(res.data.compTotalCount);
                    setIsTableActive(true)
                } else {
                    setQuestions(res.data.finalData)
                    setTotalCount(res.data.totalCount);
                    setIsTableActive(true)
                }
                localStorage.setItem("qaValue", qaData.status.value)
            }
        }).catch(err => {
            toast.error("Unable to fetch Questions")
        })
            .finally(() => setLoading(false))
    }


    const status = [
        {
            value: "Verified",
            label: "Verified"
        },
        {
            value: "Not Verified",
            label: "Not-Verified"
        }
    ]

    useEffect(() => {
        if (qaData?.status?.value && qaData?.subject?.value && qaData?.topic?.value) {
            getAllQuestions();
        }
    }, [currentPage, perPage, isComprehension])




    useEffect(() => {
        getCompanySubject();
    }, [])

    useEffect(() => {
        if (qaData?.subject) {
            getAllTopics();
        }
    }, [qaData?.subject])

    return (
        <>
            <Card className="reg-card box-shadow">
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Label className="fw-bold xcn-text-12 text-muted"> 1. Select Subject</Form.Label>
                            <Select
                                className="select"
                                options={subjects}
                                defaultValue={qaData?.subject}
                                onChange={(e: any) => handleSelectValue(e, "subject")}
                                isSearchable
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Label className="fw-bold xcn-text-12 text-muted">2. Select Topics</Form.Label>
                            <Select
                                options={topics}
                                onChange={(e: any) => handleSelectValue(e, "topic")}
                                defaultValue={qaData?.topic}
                                isSearchable
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Label className="fw-bold xcn-text-12 text-muted">3. Status</Form.Label>
                            <Select

                                options={status}
                                defaultValue={qaData?.status}
                                onChange={(e: any) => handleSelectValue(e, "status")}
                                isSearchable

                            />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                        <Button size="sm" className="mt-3 xcn-text-12 fw-bold" onClick={getAllQuestions} disabled={loading} >
                            {
                                !loading ?
                                    <>
                                        Next <MdOutlineNavigateNext className="text-white" />
                                    </>
                                    :
                                    <span className="d-flex align-items-center justify-content-center gap-2">
                                        Loading <Spinner animation="border" variant="light" size="sm" />
                                    </span>
                            }
                        </Button>
                    </div>
                </Card.Body>
            </Card>
            {isTableActive &&
                <Row className="mt-5">
                    <Col>
                        <div>
                            <Form.Group className='d-flex align-items-center gap-2'>
                                <Form.Label className="fw-bold">
                                    Comprehension Questions
                                </Form.Label>
                                <Form.Switch className="mb-2" onChange={(e: any) => setIsComprehension(e.target.checked)} />
                            </Form.Group>
                        </div>
                        <QuestionNotVerified
                            data={questions}
                            loading={loading}
                        />
                        <div className="text-center my-3">
                            <TablePagination
                                total={totalCount}
                                currentPage={currentPage}
                                perPage={perPage}
                                handlePageChange={(e: number) => {
                                    setCurrentPage(e);
                                }}
                                setPerPage={(e: number) => { setPerPage(e); }}
                            />
                        </div>
                    </Col>
                </Row>
            }

            {
                !isTableActive && loading &&
                <Skeleton height={300} />
            }
        </>
    )
}