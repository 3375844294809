import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import SubjectService from "../../services/subjects.service";
import CustomToggle from "../../Menu/CustomMenu";
import TablePagination from "../../components/Pagination/Table.paginaition";
import { StringHelper } from "../../helper/string.helper";
import EditSubject from "../../Modal/EditSubject.modal";
import AddSubject from "../../Modal/AddSubject.modal";
import UpdateSubjectModal from "../../Modal/UpdateSubject.modal";

export default function UpdatedSubjectIndex() {

    const navigate = useNavigate();

    const [subjects, setSubjects] = useState<any>();
    const [editSubject, setEditSubject] = useState<any>(undefined);
    const [addSubjectShow, setAddSubjectShow] = useState<boolean>(false);
    const [subjectIndex, setSubjectIndex] = useState<any>(undefined);

    const [searchText, setSearchText] = useState<string>("");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);

    const getAllSubjects = async () => {
        await SubjectService.getAllSubjects(searchText, currentPage, perPage).then((res) => {
            if (res.status == 200) {
                setSubjects(res.data.subjects)
                setTotalCount(res.data.totalSubjects)
            }
        }).catch((err) => {
            console.log(err);

        })
    }

    useEffect(() => {
        getAllSubjects();
    }, [editSubject, addSubjectShow, searchText, currentPage, perPage])


    return (
        <>
            <EditSubject
                subjectId={editSubject}
                setShow={() => setEditSubject(undefined)}
            />

            <AddSubject
                show={addSubjectShow}
                setShow={setAddSubjectShow}
            />
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="header">Subjects ({subjects?.length || "0"})</h5>
                <Form.Group>
                    <input
                        type="text"
                        className="xcn-search-bar form-control"
                        placeholder="type text to search"
                        onChange={(e: any) => setSearchText(e.target.value)}
                    />
                </Form.Group>
            </div>

            <div className="d-flex justify-content-between align-items-center">
                <div className="text-secondary fw-bold xcn-text-12">
                    All Subjects
                </div>
                <div>
                    <Button size="sm" onClick={() => setAddSubjectShow(true)}>
                        Add Subject
                    </Button>
                </div>

            </div>
            {subjects && subjects.length > 0 && subjects.map((data: any, index: number) => {
                return (
                    <>
                        <Card className = "shadow-sm mt-3 border-none">
                            <Card.Body>
                                <Row className="xcn-table-row text-secondary align-items-center">
                                    <Col md={1}>{index + 1}</Col>
                                    <Col md={3}>
                                        Name:{" "}
                                        <span className="text-info text-decoration-underline">
                                            {data.name}
                                        </span>
                                    </Col>
                                    <Col md={6}>
                                        Topics:{" "}
                                        <div className="d-flex flex-wrap gap-2">
                                            {data.topics.map((topic: any, index: number) => {
                                                return (
                                                    <div>
                                                        <span>
                                                            {StringHelper.Truncate(topic.name, 10)}
                                                        </span>
                                                        <span>{index < data?.topics?.length - 1 && ","}</span>
                                                    </div>
                                                )
                                            })}{" "}
                                        </div>
                                    </Col>
                                    <Col md={2} className="d-flex justify-content-end fs-5">
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                as={CustomToggle}
                                                id="dropxdown-custom-components"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEllipsisVertical}
                                                    className="text-primary xcn-link-pointer"
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setSubjectIndex(index)}>
                                                    Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        navigate("/subject/" + data._id)
                                                    }}
                                                >
                                                    Add Topics
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </>
                )
            })}
            <div className="text-center mt-3">
                <TablePagination
                    total={totalCount}
                    currentPage={currentPage}
                    perPage={perPage}
                    handlePageChange={(e: number) => {
                        setCurrentPage(e)
                    }}
                    setPerPage={(e: number) => { setPerPage(e) }}
                />

            </div>

            <UpdateSubjectModal
                show={subjectIndex >= 0 ? true : false}
                handleClose={() => {
                    setSubjectIndex(undefined)
                    getAllSubjects();
                }}
                data={subjects && subjects[subjectIndex]}
            />
        </>
    )
}