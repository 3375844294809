import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Container, Row, Col, Badge, Tabs, Tab, Spinner } from 'react-bootstrap';
import Select from 'react-select'
import QP_GenerationService from '../../services/qp_generation.service';
import toast from 'react-hot-toast';
import UserProfileService from '../../services/userprofile.service';
import PublicService from '../../services/public.service';
import { FaTrash } from 'react-icons/fa';
import QPModalNew from './QPModal';

interface Question {
    q_db: string;
    q_subject: string;
    q_count: number;
    q_topic: string;
    q_language: string[];
    q_type: string;
    q_difficulty: number | '';
}

interface Section {
    sectionName: string;
    question: Question[];
}

const initialData: Section[] = [
    {
        sectionName: "Section 1",
        question: [
            {
                q_db: "",
                q_subject: "",
                q_count: 0,
                q_topic: "",
                q_language: [],
                q_type: "",
                q_difficulty: 0
            }
        ]
    }
];

export default function QPGenerationNew() {
    const [data, setData] = useState<Section[]>(initialData);
    const [subjectOptions, setSubjectOptions] = useState<any[]>([]);
    const [topicOptions, setTopicOptions] = useState<any[]>([]);
    const [qTypes, setQTypes] = useState<any[]>([]);
    const [languages, setLanguages] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [generatedQP, setGeneratedQP] = useState<any[]>([]);
    const [showQP, setShowQP] = useState<boolean>(false);

    const handleChange = (
        sectionIndex: number,
        questionIndex: number,
        field: keyof Question,
        value: string | number | any[]
    ) => {
        const newData = structuredClone(data);

        if (field === 'q_language') {
            // For language field, convert string to array
            if (typeof value === 'object') {
                newData[sectionIndex].question[questionIndex][field] = value?.map((item: any) => item.value);
            }
        } else if (field === 'q_count' || field === 'q_difficulty') {
            // For numeric fields
            newData[sectionIndex].question[questionIndex][field] = Number(value);
        } else {
            // For string fields
            newData[sectionIndex].question[questionIndex][field] = value.toString();
        }

        setData(newData);
    };

    const handleSectionChange = (index: number, field: keyof Section, value: any) => {
        const newData = [...data];
        newData[index][field] = value;
        setData(newData);
    };

    const addSection = () => {
        setData([...data, {
            sectionName: "Section " + (data.length + 1),
            question: [{
                q_db: "",
                q_subject: "",
                q_count: 0,
                q_topic: "",
                q_language: [],
                q_type: "",
                q_difficulty: 0
            }]
        }]);
        setActiveTab(data.length);
    };

    const addQuestion = (sectionIndex: number) => {
        const newData = [...data];
        newData[sectionIndex].question.push({
            q_db: "",
            q_subject: "",
            q_count: 0,
            q_topic: "",
            q_language: [],
            q_type: "",
            q_difficulty: 0
        });
        setData(newData);
    };

    function handleSubjectChange(sectionIndex: number, questionIndex: number, e: any) {
        handleChange(sectionIndex, questionIndex, 'q_subject', e.value);
        setTopicOptions(subjectOptions.find((subject: any) => subject._id === e.value)?.topics || []);
    }

    function handleDeleteSection(sectionIndex: number) {
        if (data.length === 1) {
            toast.error("At least one section is required");
            return
        }

        setActiveTab(sectionIndex - 1 > 0 ? sectionIndex - 1 : 0);
        const newData = [...data];
        newData.splice(sectionIndex, 1);
        setData(newData);
    }

    function handleDeleteQuestion(sectionIndex: number, questionIndex: number) {
        if (data[sectionIndex].question.length === 1) {
            toast.error("At least one question is required");
            return
        }
        const newData = [...data];
        newData[sectionIndex].question.splice(questionIndex, 1);
        setData(newData);
    }

    const fetchSubjects = async () => {
        try {
            const res = await QP_GenerationService.getCompanySubject();
            if (res.status === 200) {
                setSubjectOptions(res.data);
            }
        } catch (error) {
            console.error("Error fetching subjects:", error);
            toast.error("Something went wrong while fetching subjects.");
        }
    };

    const getQType = async () => {
        await UserProfileService.getAllQuestionTypes()
            .then(res => {
                const qtypes_arr = Object.values(res.data).map((data) => {
                    return {
                        label: data,
                        value: data
                    }
                })
                setQTypes(qtypes_arr);
            })
    }

    const getLanguage = async () => {
        await PublicService.getLanguage().then(res => {
            if (res.status === 200) {
                const language = Object.values(res.data).map((data) => {
                    return {
                        label: data,
                        value: data
                    }
                })
                setLanguages(language);
            }
        })
    }

    async function handleSubmit() {
        setLoading(true);
        await QP_GenerationService.createQPNew({ sections: data })
            .then(res => {
                if (res.status === 200) {
                    toast.success(res.data?.message || "Question Paper Generated Successfully");
                    setGeneratedQP(res.data?.finalSectionsData || []);
                    setShowQP(true);
                }
            })
            .catch(err => {
                toast.error(err?.response?.data?.message || err?.response?.data || "Something went wrong");
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchSubjects();
        getQType();
        getLanguage();
    }, []);

    return (
        <Container className="mt-4">
            <div className="d-flex align-items-center justify-content-between mb-2">
                <h5>Question Paper Generator</h5>
                <Button size='sm' variant="secondary" onClick={addSection} className="px-4">
                    + Add New Section
                </Button>
            </div>

            <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(Number(k))}
                id="section-tabs"
                className=""
            >
                {data.map((section, sectionIndex) => (
                    <Tab
                        key={sectionIndex}
                        eventKey={sectionIndex}
                        className='p-2 border border-top-0'
                        title={
                            <div className="d-flex align-items-center gap-2">
                                {
                                    sectionIndex === activeTab ?
                                        <>
                                            <Form.Control
                                                type="text"
                                                placeholder="Section Name"
                                                value={section.sectionName || `Section ${sectionIndex + 1}`}
                                                onChange={(e) => handleSectionChange(sectionIndex, 'sectionName', e.target.value)}
                                                onClick={(e) => e.stopPropagation()}
                                                className={'bg-light border-0 mb-0 pb-0'}
                                                style={{ maxWidth: '120px' }}
                                            />
                                            <Badge
                                                bg="danger"
                                                className="cursor-pointer"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteSection(sectionIndex);
                                                }}
                                            >
                                                <FaTrash />
                                            </Badge>
                                        </> :
                                        <p className='m-0 text-dark'>{(section.sectionName || `Section ${sectionIndex + 1}`).slice(0, 20)}</p>
                                }
                            </div>
                        }
                    >
                        <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
                            <h5>Questions</h5>
                            <Button size='sm' variant="secondary" onClick={() => addQuestion(sectionIndex)} className="px-4">
                                + Add New
                            </Button>
                        </div>

                        {section.question.map((question, questionIndex) => (
                            <Card key={questionIndex} className="mb-3">
                                <Card.Header className="bg-primary text-white">
                                    <Row className="align-items-end">
                                        <Col xs="auto">
                                            <div className="d-flex gap-2">
                                                <Badge bg="light" text="dark">
                                                    Question {questionIndex + 1}
                                                </Badge>
                                                <Badge
                                                    bg="light"
                                                    text="danger"
                                                    className='cursor-pointer'
                                                    onClick={() => handleDeleteQuestion(sectionIndex, questionIndex)}
                                                >
                                                    <FaTrash />
                                                </Badge>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="g-3">
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>AI or Manual Questions</Form.Label>
                                                <Select
                                                    className="select"
                                                    options={db_options}
                                                    value={db_options.find((option: any) => option?.value === question.q_db)}
                                                    onChange={(e) => handleChange(sectionIndex, questionIndex, 'q_db', e?.value)}
                                                    isSearchable
                                                    isClearable
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Subject</Form.Label>
                                                <Select
                                                    name="subjects"
                                                    options={subjectOptions?.map((subject) => ({
                                                        value: subject._id,
                                                        label: subject.name
                                                    }))}
                                                    value={{
                                                        label: subjectOptions.find((subject) =>
                                                            subject._id === question.q_subject
                                                        )?.name,
                                                        value: question.q_subject
                                                    }}
                                                    onChange={(e) => handleSubjectChange(sectionIndex, questionIndex, e)}
                                                    isSearchable
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Topic</Form.Label>
                                                <Select
                                                    name="topics"
                                                    options={topicOptions?.map((topic) => ({
                                                        value: topic._id,
                                                        label: topic.name
                                                    }))}
                                                    value={{
                                                        label: topicOptions.find((topic) =>
                                                            topic._id === question.q_topic
                                                        )?.name,
                                                        value: question.q_topic
                                                    }}
                                                    onChange={(e: any) => handleChange(sectionIndex, questionIndex, 'q_topic', e?.value)}
                                                    isSearchable
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Language</Form.Label>
                                                <Select
                                                    options={languages}
                                                    // value={question.q_language}
                                                    onChange={(e: any) => handleChange(sectionIndex, questionIndex, 'q_language', e)}
                                                    isSearchable
                                                    isMulti
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Number of Questions</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Count"
                                                    value={question.q_count}
                                                    onChange={(e) => handleChange(sectionIndex, questionIndex, 'q_count', parseInt(e.target.value))}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Question Type</Form.Label>
                                                <Form.Select
                                                    value={question.q_type}
                                                    onChange={(e) => handleChange(sectionIndex, questionIndex, 'q_type', e.target.value)}
                                                >
                                                    <option value="">Select Type</option>
                                                    {qTypes?.length > 0 && qTypes.map((type) => (
                                                        <option key={type?.value} value={type?.label}>
                                                            {type?.label}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Difficulty Level</Form.Label>
                                                <Form.Select
                                                    value={question.q_difficulty}
                                                    onChange={(e) => handleChange(sectionIndex, questionIndex, 'q_difficulty', parseInt(e.target.value))}
                                                >
                                                    <option value="">Select Difficulty</option>
                                                    {difficultyOptions.map((option: any) => (
                                                        <option key={option?.value} value={option?.value}>
                                                            {option?.label}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        ))}
                    </Tab>
                ))}
            </Tabs>

            <Row>
                <Col md={12}>
                    <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                        {loading ? <span>Generating...<Spinner animation="border" /></span> : "Generate Questions"}
                    </Button>
                </Col>
            </Row>
            <QPModalNew
                show={showQP}
                handleClose={() => setShowQP(false)}
                sections={generatedQP}
            />
        </Container>
    );
}

const db_options: any = [
    { value: 'ai', label: 'AI Generated' },
    { value: 'self', label: 'Manually Added' }
];

const difficultyOptions: any = new Array(10).fill(null).map((_, index) => ({
    value: index + 1,
    label: index + 1
}));