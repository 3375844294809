import React from 'react';

export interface IChartItem {
    month: number;
    count: number;
}

interface IChartData {
    data: IChartItem[];
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const LineChart: React.FC<IChartData> = ({ data }) => {
    const counts = data.map(item => item.count);
    const max = Math.max(...counts);
    const min = Math.min(...counts);
    const range = max - min;

    // Calculate points for the polyline
    const points = data.map((item, index) => {
        const x = 20 + (index * (260 / (data.length - 1))); // Leave margin on sides
        const y = 80 - ((item.count - min) / range) * 60; // Scale to leave room for labels
        return { x, y, value: item.count, month: MONTHS[item.month - 1] };
    });

    const polylinePoints = points.map(point => `${point.x},${point.y}`).join(' ');

    return (
        <div style={{ width: '100%' }}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 300 100"
                preserveAspectRatio="xMidYMid meet"
            >
                {/* Grid lines */}
                <g className="grid-lines">
                    {[20, 40, 60, 80].map((y) => (
                        <line
                            key={y}
                            x1="20"
                            y1={y}
                            x2="280"
                            y2={y}
                            stroke="#eee"
                            strokeWidth="1"
                        />
                    ))}
                </g>

                {/* Main line */}
                <polyline
                    points={polylinePoints}
                    fill="none"
                    stroke="#0d6efd"
                    strokeWidth="2"
                />

                {/* Data points and labels */}
                {points.map((point, index) => (
                    <g key={index}>
                        {/* Data point circle */}
                        <circle
                            cx={point.x}
                            cy={point.y}
                            r="3"
                            fill="#0d6efd"
                        />

                        {/* Value label */}
                        <text
                            x={point.x}
                            y={point.y - 8}
                            textAnchor="middle"
                            fontSize={10}
                            fill="#666"
                            style={{ fontFamily: 'Arial' }}
                        >
                            {point.value}
                        </text>

                        {/* Month label */}
                        <text
                            x={point.x}
                            y="95"
                            textAnchor="middle"
                            fontSize="8"
                            fill="#666"
                            style={{ fontFamily: 'Arial' }}
                        >
                            {point.month}
                        </text>
                    </g>
                ))}
            </svg>
        </div>
    );
};