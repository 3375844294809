import React, { useState } from "react";
import { Badge, Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";

interface IQuestionsProps {
    questions: any[];
    handleSaveQuestion: (data: any) => void;
}
export default function Questions({ questions, handleSaveQuestion }: IQuestionsProps) {
    const [selectedQuestion, setSelectedQuestion] = useState<any>([]);

    function handleSelectQuestion(data: any) {
        if (selectedQuestion.includes(data)) {
            setSelectedQuestion(selectedQuestion.filter((item: any) => item !== data));
        } else {
            setSelectedQuestion([...selectedQuestion, data]);
        }
    }

    function handleSelectAll() {
        setSelectedQuestion(questions);
    }

    return (
        <>
            <Card className="my-3 shadow">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <h5 className="text-primary">Questions</h5>
                    <div className="d-flex gap-2">
                        <Button variant="secondary" size="sm" onClick={handleSelectAll}>
                            Select All
                        </Button>
                        <Button
                            variant="primary"
                            size="sm"
                            disabled={selectedQuestion.length === 0}
                            onClick={() => handleSaveQuestion(selectedQuestion)}
                        >
                            Save {selectedQuestion.length > 0 && `(${selectedQuestion.length})`}{" "}
                            Questions
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    {questions?.length > 0 ? (
                        <Row>
                            {questions.map((data: any) => (
                                <Col md={6} key={data.id}>
                                    <MCQQuestionCard
                                        data={data}
                                        isSelected={selectedQuestion.includes(data)}
                                        onSelectQuestion={() => handleSelectQuestion(data)}
                                    />
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p>No Questions Created</p>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}

interface IMCQQuestionCardProps {
    data: any;
    isSelected?: boolean;
    onSelectQuestion?: any;
    showSelect?: boolean;
}

export function MCQQuestionCard({
    data,
    isSelected,
    onSelectQuestion,
    showSelect = true,
}: IMCQQuestionCardProps) {
    const checkboxId = `select-${data?.id}`;

    return (
        <Card
            className="my-3 shadow question-card"
            style={{
                border: isSelected ? "2px solid #007bff" : "1px solid #dee2e6",
                backgroundColor: isSelected ? "#f0f8ff" : "#fff",
            }}
        >
            <Card.Header className="d-flex justify-content-between align-items-center">
                <p className="mb-0">
                    MCQ ID: <span className="fw-bold">{data?.id}</span>
                </p>
                {
                    showSelect &&
                    <div>
                        <Form.Check
                            type="checkbox"
                            id={checkboxId}
                            checked={isSelected}
                            onChange={onSelectQuestion}
                            label="Select Question"
                            className="cursor-pointer"
                        />
                    </div>
                }
            </Card.Header>
            <Card.Body>
                <Card.Title>{data?.question}</Card.Title>
                <ListGroup variant="flush" className="mt-3">
                    {data?.options && Object.entries(data?.options).map(([key, value]: any) => (
                        <ListGroup.Item
                            key={key}
                            className={`d-flex justify-content-between ${key === data?.correct_answer ? "bg-success text-white" : ""
                                }`}
                            style={{
                                backgroundColor: isSelected ? "#f0f8ff" : "",
                            }}
                        >
                            <span>
                                <strong>{key}</strong>: {value}
                            </span>
                            {key === data?.correct_answer && (
                                <Badge bg="light" text="success">
                                    Correct Answer
                                </Badge>
                            )}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <div className="mt-4">
                    <p>
                        <strong>Confidence Score: </strong>
                        <Badge bg="info">{data?.confidenceScore}%</Badge>
                    </p>
                    <p>
                        <strong>Explanation:</strong> {data?.explanation}
                    </p>
                    {
                        data?.citation &&
                        <p>
                            <strong>Citation:</strong> {data?.citation}
                        </p>
                    }
                </div>
            </Card.Body>
        </Card>
    );
}