import React from 'react';
import { Card } from 'react-bootstrap';
import { FaArrowDown, FaArrowUp, FaUser } from 'react-icons/fa';

interface LeaderboardProps {
    title: string;
    users: any[];
}

export const Leaderboard: React.FC<LeaderboardProps> = ({ title, users }) => {
    return (
        <Card>
            <Card.Body>
                <h5 className="mb-4">{title}</h5>
                {users?.map((user, index) => (
                    <div key={index} className="d-flex align-items-center mb-3">
                        <FaUser className="me-3" size={32}/>
                        <div className="flex-grow-1">
                            <div className="fw-bold">{user?.name || "N/A"}</div>
                            <div className="text-muted">
                                {user.points} Points • {user.accuracy}% Correct
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="me-2">{user.rank}</span>
                            {user.trend === 'up' && <FaArrowUp className="text-success" size={16} />}
                            {user.trend === 'down' && <FaArrowDown className="text-danger" size={16} />}
                        </div>
                    </div>
                ))}
            </Card.Body>
        </Card>
    );
};