import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Leaderboard } from '../LeaderBoard';
import { leaderboardData } from '../../../data/leaderboardData';

interface IProps {
    data: any;
}

export const LeaderboardOverview: React.FC<IProps> = (props) => {
    return (
        <>
            <Row className="g-4">
                <h3>Leaderboards</h3>
                <Col md={6}>
                    <Leaderboard
                        title="Create Leaderboard"
                        users={props.data?.authors}
                    />
                </Col>
                {/* <Col md={6}>
                <Leaderboard
                title="QA Leaderboard"
                users={leaderboardData.qaTeam}
                />
                </Col> */}
            </Row>
        </>
    );
};